<template>
  <div>
    <v-card :loading="loading">
      <v-card-title class="primary">
        <span v-if="selectedPersonAccount.personType === 'Applicant Tenant'" class="text-h5 white--text">{{ propertyDetails.TenancyProperty.AddressNumber }} {{ propertyDetails.TenancyProperty.Address1 }}</span>
        <span v-if="selectedPersonAccount.personType === 'Applicant Buyer'" class="text-h5 white--text">{{ propertyDetails.AddressNumber }} {{ propertyDetails.Address1 }}</span>
        <v-spacer></v-spacer>
        <v-btn color="white" @click="closeDialog" text>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-5">
        <v-textarea
          v-if="!feedbackSentMsg"
          v-model="feedbackText"
          auto-grow
          outlined
          rows="3"
          color="primary"
          name="input-7-4"
          placeholder="Let us know your thoughts on this property."
        ></v-textarea>
        <div v-else class="d-flex align-center">
          <v-icon v-if="feedbackSuccess" color="green" large>mdi-check</v-icon>
          <p class="text-h6 my-0" :class="feedbackSuccess ? '' : 'red--text'">{{ feedbackSentMsg }}</p>
        </div>
      </v-card-text>

      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn v-if="!feedbackSentMsg" @click="sendFeedback" depressed color="primary" width="200">
          <span class="white--text">Send feedback</span>
        </v-btn>
        <v-btn v-else @click="closeDialog" depressed color="secondary" width="200">
          <span class="white--text">Close</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { API } from 'aws-amplify'

export default {
  name: 'prospectFeedback',
  props: {
    propertyDetails: {
      type: Object
    }
  },
  data() {
    return {
     feedbackText: '',
     loading: false,
     feedbackSentMsg: '',
     feedbackSuccess: null,
    }
  },
  methods: {
    sendFeedback() {
      this.loading = 'secondary' 
      
      const submissionModel = {
        PropertyID: this.propertyDetails.OID,
        Feedback: this.feedbackText
      }
      const postParams = {
        body: submissionModel
      }

      API.post('agentSignMainRest', `/post?query=/${this.clientName}/diary/${this.personOID}/appointments/feedback`, postParams)
        .then(res => {
          console.log('Feedback summission res: ', res)
          if(res.status === '200') {
            this.loading = false
            this.feedbackSentMsg = 'Feedback submitted successfully!'
            this.feedbackSuccess = true
          } else {
            this.loading = false
            this.feedbackSentMsg = 'ERROR! Could not submit feedback. Please try again. Contact support if issue continues.'
            this.feedbackSuccess = false
          }
        })
    },
    closeDialog() {
      this.$emit('closeFeedbackDialog')
    }
  },
  computed: {
    ...mapState({
      selectedPersonAccount: state => state.selectedPersonAccount,
      clientName: state => state.selectedPersonAccount.companyShortname,
      personOID: state => state.selectedPersonAccount.personOID,
    })
  },
}
</script>

<style>

</style>