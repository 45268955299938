<template>
  <div>
    <v-card>
      <v-card-title class="primary white--text">
        <span>Existing offers</span>
        <v-spacer></v-spacer>
        <v-btn @click="closeViewOffer" color="white" text>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        
        <!-- {{ propertyViewOffer }} -->

        <v-card 
          v-for="offer in existingOffers" 
          :key="offer.OID"
          outlined
          flat
          class="mt-3 pa-3"
        >
          <v-card-text>
            <p class="my-0 text-body-1">
              <span class="font-weight-medium">Offer made:</span>
              {{ offer.OfferMade | formatDate }}
            </p>
            <p class="my-0 text-body-1">
              <span class="font-weight-medium">Amount:</span>
              {{ offer.Amount | filterPriceGlobal }}
            </p>
            <p class="my-0 text-body-1">
              <span class="font-weight-medium">Status:</span>
              {{ offer.OfferStatus }}
            </p>
          </v-card-text>
          <!-- <v-card-actions>
            <v-btn 
              dark
              depressed
              outlined
              color="red">cancel offer</v-btn>
          </v-card-actions> -->
        </v-card>
        <v-btn 
          @click="makeNewOffer"
          color="primary"
          class="mt-5"
          depressed
        >
          New offer
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="offerDialog"
      persistent
      max-width="700px"
      transition="dialog-transition"
    >
      <ProspectOffer
        @cancelOffer="cancelOffer"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment/src/moment'
import ProspectOffer from '@/components/prospects/ProspectOffer.vue';

export default {
  name: 'prospectViewOffer',
  props: {
    existingOffers: {
      type: Array
    }
  },
  components: {
    ProspectOffer
  },
  data() {
    return {
      offerDialog: false,
    }
  },
  computed: {
    ...mapState({
      propertyViewOffer: state => state.propertyViewOffer,
      selectedPersonAccount: state => state.selectedPersonAccount,
      selectedProperty: state => state.selectedProperty,
      clientName: state => state.selectedPersonAccount.companyShortname,
      personOID: state => state.selectedPersonAccount.personOID,
      propertyID: state => state.selectedProperty.OID,
    }),
  },
  methods: {
    closeViewOffer() {
      this.$emit('closeViewOffer') // emits to PropertyCard.vue
    },
    makeNewOffer() {
      this.$store.commit('SET_PROPERTY_MAKE_OFFER', this.propertyViewOffer)
      this.offerDialog = true
    },
    cancelOffer() {
      this.offerDialog = false
      this.$emit('closeUpdateViewOffer')
    }
  },
  filters: {
    formatDate(val) {
      return moment(val).format('DD/MM/YYYY, h:mm a')
    },
  }
}
</script>

<style>

</style>