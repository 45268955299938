<template>
  <v-row class="fill-height">
    <v-col>
      <v-sheet height="64">
        <v-toolbar
          flat
        >
          <!-- <v-btn
            outlined
            small
            rounded
            class="mr-5"
            color="grey lighten-1"
            @click="setToday"
          >
            Today
          </v-btn> -->
          <v-btn
            fab
            small
            depressed
            class="mr-2"
            color="primary"
            @click="prev"
          >
            <v-icon small>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-btn
            fab
            small
            depressed
            color="primary"
            @click="next"
          >
            <v-icon small>
              mdi-chevron-right
            </v-icon>
          </v-btn>

          <v-toolbar-title v-if="$refs.calendar" class="d-none d-sm-block ml-4">
            {{ $refs.calendar.title }}
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <div class="d-none d-md-flex">
            <v-btn 
              @click="setToday"
              color="secondary" 
              depressed
            >Today</v-btn>
            <v-btn 
              @click="setToTomorrow"
              color="secondary" 
              depressed
              class="mx-2"
            >Next 24 hrs</v-btn>
            <v-btn 
              @click="setToNext7Days"
              color="secondary" 
              depressed 
            >Next 7 days</v-btn>
          </div>
          <v-menu
            bottom
            right
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="d-md-none"
                color="primary"
                outlined
                v-bind="attrs"
                v-on="on"
              >
                <span>{{ selectTitle }}</span>
                <v-icon right>
                  mdi-menu-down
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="setToday">
                <v-list-item-title>Today</v-list-item-title>
              </v-list-item>
              <v-list-item @click="setToTomorrow">
                <v-list-item-title>Next 24 hrs</v-list-item-title>
              </v-list-item>
              <v-list-item @click="setToNext7Days">
                <v-list-item-title>Next 7 days</v-list-item-title>
              </v-list-item>
              <!-- <v-list-item @click="type = 'month'">
                <v-list-item-title>Month</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = '4day'">
                <v-list-item-title>4 days</v-list-item-title>
              </v-list-item> -->
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="600">
        <v-calendar
          ref="calendar"
          v-model="focus"
          :start="calendarStartDate"
          :events="availableTimes"
          event-start="Start"
          event-end="End"
          event-color="secondary"
          :type="type"
          color="secondary"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          first-time="07:00"
          interval-count="17`"
          :interval-height="intervalHeight"
          :weekdays="weekdays"
          :short-weekdays="shortWeekdays"
        >
          <template v-slot:event="event" class="">
            <span class="ml-1 font-weight-medium text-body-1">{{ event.eventParsed.input.StaffName }}</span>
            <p class="mt-n1 ml-1">{{ event.eventParsed.input.Start | returnTime }} - {{ event.eventParsed.input.End | returnTime }}</p>
          </template>
        </v-calendar>
      </v-sheet>
    </v-col>

    <v-dialog
      v-model="selectedOpen"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card
        max-width="550px"
        flat
      >
        <v-card-title class="primary white--text font-weight-light">
          <v-toolbar-title>
            Viewing details
          </v-toolbar-title>
        </v-card-title>
        <v-card-text class="pt-6 text-center">
          <p class="text-h6 my-0 font-weight-medium">{{ selectedEvent.Start |  formatDate }} </p>
          <p class="text-h6 my-0 font-weight-light"> 
            with 
            <span class="font-weight-medium">{{ selectedEvent.StaffName }}</span>
          </p>
        </v-card-text>
        <v-card-actions class="px-3">
          <v-btn
            outlined
            color="red"
            @click="selectedOpen = false"
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :loading="loading"
            depressed
            width="200"
            color="primary"
            dark
            @click="bookViewing"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import moment from 'moment/src/moment'
import { mapState } from 'vuex'
import { API } from 'aws-amplify'

export default {
  name: 'viewingCalendar',
  props: {
    propertyOID: {
      type: String
    },
    availableTimes: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    loading: false,
    calendarStartDate: moment().format("YYYY-MM-DD"),
    tomorrowsDate: moment().add(1, 'days').format("YYYY-MM-DD"),
    focus: '',
    type: 'day',
    typeToLabel: {
      month: 'Month',
      week: 'Week',
      day: 'Day',
      '4day': '4 Days',
    },
    weekdays: [1, 2, 3, 4, 5, 6, 0],
    shortWeekdays: false,
    intervalHeight: 100, // on day only
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    showNoTimesAlert: false
  }),
  mounted () {
    this.$refs.calendar.checkChange()
    this.setToTomorrow()
  },
  methods: {
    bookViewing() {
      this.loading = true
      const clientName = this.selectedPersonAccount.companyShortname
      const personOID = this.selectedPersonAccount.personOID

      const startDate = this.selectedEvent.Start.substr(0,10)
      const startTime = this.selectedEvent.Start.substr(-5)
      const startDateTime = `${startDate}T${startTime}:00.000Z`
      const endDate = this.selectedEvent.End.substr(0,10)
      const endTime = this.selectedEvent.End.substr(-5)
      const endDateTime = `${endDate}T${endTime}:00.000Z`

      const appointmentDetails = {
        Subject: "",
        ExtraComments: "",
        Allocation: {
          Start: startDateTime,
          End: endDateTime,
          StaffName: this.selectedEvent.StaffName,
          StaffID: this.selectedEvent.StaffID
        },
        PropertyOwnableIDs: [
          this.propertyOID
        ]
      }
      console.log('Appointment Details: ', appointmentDetails)

      const postParams = {
        body: appointmentDetails
      }

      API.post('agentSignMainRest', `/post?query=/${clientName}/diary/${personOID}/appointments`, postParams)
        .then( res => {
          this.loading = false
          console.log('Book viewing response: ', res)
          if(res.status === "200") {
            this.selectedOpen = false
            this.$emit('appointmentBooked') // emits to ProspectBookViewing.vue
          } else {
            this.loading = false
            console.log('Error booking appointment!')
          }
        })
    },
    viewDay ({ date }) {
      this.focus = date
      this.type = 'day'
    },
    getEventColor (event) {
      return event.color
    },
    setToday () {
      this.focus = ''
      this.type = "day"
    },
    setToTomorrow() {
      this.focus = this.tomorrowsDate
      this.type = 'day'
    },
    setToNext7Days() {
      this.focus = this.tomorrowsDate
      this.type = 'month'
    },
    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },
    showEvent ({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        setTimeout(() => {
          this.selectedOpen = true
        }, 10)
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        setTimeout(open, 10)
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
  },
  computed: {
    ...mapState({
      selectedPersonAccount: state => state.selectedPersonAccount,
      selectedProperty: state => state.selectedProperty
    }),
    selectTitle() {
      if(this.type === 'day' && this.focus === '') return 'Today'
      if(this.type === 'day') return 'Next 24 hrs'
      return 'Next 7 days'
    }
  },
  filters: {
    returnTime(val) {
      if(val) return moment(val).format("hh:mm a")
    },
    formatDate(val) {
      return moment(val).format('dddd Do MMMM, hh:mm a')
    }  

  }
}
</script>