<template>
  <div>
    <v-card 
      v-if="selectedProperty" 
      flat 
      class="white rounded-sm"
    >
      <v-card-title 
        class="pa-2"
        :class="cardColor"
      >
        <span 
          v-if="selectedPersonAccount.personType === 'Applicant Tenant' " 
          class="d-flex d-sm-none align-center white--text text-body-1 font-weight-light"
        >
          {{ selectedProperty.TenancyProperty.BedroomCount }} 
          <span class="mx-1" v-if="selectedProperty.TenancyProperty.BedroomCount <= 1">bed</span>
          <span class="mx-1" v-else>beds</span> 
          {{ selectedProperty.TenancyProperty.PropertyType }} 
          @ <span class="font-weight-medium ml-2 text-h5">{{ selectedProperty.RentAdvertised | filterPriceGlobal  }}</span> 
            <span class="align-self-center ml-1 mt-1 text--caption">pcm</span> 
        </span>
        <span 
          v-if="selectedPersonAccount.personType === 'Applicant Buyer' " 
          class="d-flex d-sm-none align-center white--text text-body-1 font-weight-light"
        >
          {{ selectedProperty.Bedrooms }} 
          <span class="mx-1" v-if="selectedProperty.Bedrooms <= 1">bed</span>
          <span class="mx-1" v-else>beds</span> 
          {{ selectedProperty.propertyType }} 
          @ <span class="font-weight-medium ml-2 text-h5">{{ selectedProperty.Price | filterPriceGlobal  }}</span> 
        </span>
        <v-spacer></v-spacer>
      </v-card-title> 
      <v-card-text class="pt-3 pb-6 pb-sm-0 px-3">
        <v-row class="top-row">
          <v-col class="left-col pa-0" cols="12" sm="4" md="5">
            <PropertyImageRow
              v-if="propertyPhotos.length" 
              :propertyPhotos="propertyPhotos" 
              @openCarousel="carouselDialog = true"
            />
            <v-col v-else cols="12" class="pa-1">
              <v-card height="225" width="100%" flat class="grey lighten-2 rounded-0 d-flex flex-column justify-center">
                <v-card-text height="225" class="d-flex flex-column align-center justify-center">
                  <v-icon x-large color="white">mdi-image-area</v-icon>
                  <p class="white--text">No images available</p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-col>
          <v-col class="right-col d-flex " cols="12" sm="8" md="7">
            <v-row>
              <v-col cols="12">
                <div class="d-flex align-start justify-space-between">
                  <div v-if="selectedPersonAccount.personType === 'Applicant Tenant'">
                    <p class="text-body-1 text-xl-h5 my-0 font-weight-medium">{{ selectedProperty.TenancyProperty.AddressNumber }} {{ selectedProperty.TenancyProperty.Address1 }}</p>
                    <p v-if="selectedProperty.TenancyProperty.Address2" class="text-body-2 text-xl-body-1 mb-0 my-0">{{ selectedProperty.TenancyProperty.Address2 }}</p>
                    <p v-else class="text-body-2 text-xl-body-1 mb-0 my-0">{{ selectedProperty.TenancyProperty.Postcode }}</p>
                  </div>
                  <div v-if="selectedPersonAccount.personType === 'Applicant Buyer'">
                    <p class="text-body-1 text-xl-h5 my-0 font-weight-medium">{{ selectedProperty.Address1 }}</p>
                    <p v-if="selectedProperty.Address2" class="text-body-2 text-xl-body-1 mb-0 my-0">{{ selectedProperty.Address2 }}</p>
                    <p v-else class="text-body-2 text-xl-body-1 mb-0 my-0">{{ selectedProperty.Postcode }}</p>
                  </div>
                  <v-spacer></v-spacer>
                  <v-btn 
                    @click="viewPropertyDetails" 
                    class="mr-3"
                    small 
                    outlined
                    color="tertiaryDark" 
                    fab
                  >
                    <v-icon size="30">mdi-information-variant</v-icon>
                  </v-btn>
                  <v-btn 
                    @click="linkToMaps"
                    fab
                    small
                    outlined 
                    color="tertiaryDark"
                  >
                    <v-icon small>mdi-google-maps</v-icon>
                  </v-btn>
                </div>

                <v-divider class="my-2"></v-divider>
                
                <div 
                  class="viewing-details-card my-3 mt-sm-8" 
                  v-if="upcomingViewingsDetails.length"
                >
                  <div class="text-center">
                    <p class="tertiaryDark--text text--darken-1 text-h5 text-sm-h4 my-0 font-weight-medium">Viewing {{ upcomingViewingsDetails[0].Start | fromNow }}</p>
                    <p class="text-body-1 text-sm-h6 my-0"> {{ upcomingViewingsDetails[0].Start | formatDateTime }}</p>
                  </div>
                </div>


                <div 
                  v-if="!upcomingViewingsDetails.length" 
                  class="description mt-1"
                >
                  <p 
                    v-if="selectedPersonAccount.personType === 'Applicant Tenant' " 
                    class="text-body-1 font-weight-bold my-0 tertiaryDark--text"
                  >
                    {{ selectedProperty.TenancyProperty.BedroomCount }} 
                    <span class="mx-1" v-if="selectedProperty.TenancyProperty.BedroomCount <= 1">bed</span>
                    <span class="mx-1" v-else>beds</span> 
                    {{ selectedProperty.TenancyProperty.PropertyType }} 
                  </p>
                  <p 
                    v-if="selectedPersonAccount.personType === 'Applicant Buyer' " 
                    class="text-body-1 font-weight-bold my-0 tertiaryDark--text"
                  >
                    {{ selectedProperty.Bedrooms }} 
                    <span class="mx-1" v-if="selectedProperty.Bedrooms <= 1">bed</span>
                    <span class="mx-1" v-else>beds</span> 
                    {{ selectedProperty.propertyType }} 
                  </p>
                  <div v-if="selectedPersonAccount.personType === 'Applicant Tenant'">
                    <span class="font-weight-light text-body-1">{{ descriptionText(selectedProperty.TenancyProperty.Description) }}</span>
                    <v-btn 
                      v-if="selectedProperty.TenancyProperty.Description.length > 220" 
                      @click="viewPropertyDetails"
                      class="pl-0"
                      color="success" 
                      text 
                      small
                    >
                      <span class="tertiaryDark--text text-body-1 custom-transform-class text-none mb-1">more</span>
                    </v-btn>
                  </div>
                  <div v-if="selectedPersonAccount.personType === 'Applicant Buyer'" >
                    <span class="font-weight-light text-body-1">{{ descriptionText(selectedProperty.Description) }}</span>
                    <v-btn 
                      v-if="selectedProperty.Description.length > 250" 
                      @click="viewPropertyDetails"
                      class="pl-0"
                      color="success" 
                      text 
                      small
                    >
                      <span class="tertiaryDark--text text-body-1 custom-transform-class text-none mb-1">more</span>
                    </v-btn>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="bottom-row" align="center">
          <v-col 
            class="rounded-bl-sm d-none d-sm-flex justify-start align-center px-2 py-2" cols="12" sm="4" md="5"
            :class="cardColor"
          >
            <div v-if="selectedPersonAccount.personType === 'Applicant Tenant' " >
              <span class="white--text font-weight-medium ml-2 text-h5 text-sm-h4 text-md-h3">{{ selectedProperty.RentAdvertised | filterPriceGlobal }}</span>
              <span class="white--text align-self-center ml-1 mt-1 text--caption">pcm</span> 
            </div>
            <div v-if="selectedPersonAccount.personType === 'Applicant Buyer' " >
              <p class="white--text font-weight-medium ml-2 mb-0 text-h5 text-sm-h4 text-md-h3">{{ selectedProperty.Price | filterPriceGlobal  }}</p> 
            </div>
          </v-col>
          <v-col cols="12" sm="8" md="7" class="py-0 d-flex justify-space-between">
            <v-btn 
              v-if="!upcomingViewingsDetails.length" 
              @click="openViewingsDialog"
              class="rounded-sm"
              color="tertiaryDark" 
              depressed
              large
              dark
              width="32%" 
              :loading="loading"
            >
              <span class="custom-transform-class text-none">Book viewing</span>
            </v-btn>
            <v-btn 
              v-if="upcomingViewingsDetails.length"
              @click="cancelViewing"
              class="rounded-sm"
              :loading="loading"
              color="red" 
              outlined
              large
              width="32%"
              dark
              depressed 
            >
              <span class="custom-transform-class text-none">Cancel viewing</span>
            </v-btn>
            <v-btn 
              @click="feedbackDialog = true"
              class="rounded-sm"
              color="tertiaryDark"
              outlined 
              large
              width="32%"
              dark
              depressed 
            >
              <span class="custom-transform-class text-none">Give feedback</span>
            </v-btn>
            <v-btn 
              v-if="!existingOffers"
              @click="makeOffer"
              class="rounded-sm"
              color="tertiaryDark"
              :outlined="!upcomingViewingsDetails.length"
              large
              dark
              width="32%" 
              depressed 
            >
              <span class="custom-transform-class text-none">Make offer</span>
            </v-btn>
            <v-btn 
              v-else
              @click="viewOffer"
              class="rounded-sm"
              color="tertiaryDark"
              :outlined="!upcomingViewingsDetails.length"
              large
              dark
              width="32%" 
              depressed 
            >
              <span class="custom-transform-class text-none">View offers</span>
            </v-btn>

          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- Dialogs ---------------------------------------------- -->
    <!-- ------------------------------------------------------ -->

    <!-- Book a viewing -->
    <v-dialog
      v-model="viewingDialog"
      max-width="1200px"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="dialog-transition"
    >
      <ProspectBookViewing
        :propertyDetails="selectedProperty"
        :availableTimes="availableTimes"
        @closeDialog="closeDialog"
        @appointmentBooked="appointmentBooked"
      />
    </v-dialog>

    <!-- Feedback -->
    <v-dialog
      v-model="feedbackDialog"
      max-width="700px"
      transition="dialog-transition"
    >
      <ProspectFeedback 
        @closeFeedbackDialog="feedbackDialog = false"
        :propertyDetails="selectedProperty"
      />
    </v-dialog>

    <!-- Make offer -->
    <v-dialog
      v-model="offerDialog"
      persistent
      max-width="700px"
      transition="dialog-transition"
    >
      <ProspectOffer
        @cancelOffer="cancelOffer"
      />
    </v-dialog>

    <!-- View offer -->
    <v-dialog
      v-model="viewOfferDialog"
      persistent
      max-width="750px"
      transition="dialog-transition"
      scrollable
    >
      <ProspectViewOffer
        :existingOffers="existingOffers"
        @closeViewOffer="viewOfferDialog = false"
        @closeUpdateViewOffer="closeUpdateViewOffer"
      />
    </v-dialog>

    <!-- Photo carousel -->
     <v-dialog
      v-model="carouselDialog"
      max-width="700px"
      transition="dialog-transition"
    >
      <v-card class="pa-3">
        <v-carousel hide-delimiters>
          <v-carousel-item
            v-for="(image) in propertyPhotos"
            :key="image.OID"
            :src="image.WebAddress"
            reverse-transition="fade-transition"
            transition="fade-transition"
          ></v-carousel-item>
        </v-carousel>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment/src/moment'
import { API } from 'aws-amplify'
import { mapState } from 'vuex'
import ProspectBookViewing from '@/components/prospects/ProspectBookViewing.vue';
import ProspectFeedback from '@/components/prospects/ProspectFeedback.vue';
import ProspectOffer from '@/components/prospects/ProspectOffer.vue';
import ProspectViewOffer from '@/components/prospects/ProspectViewOffer.vue';
import PropertyImageRow from '@/components/property/PropertyImageRow.vue';

export default {
  name: 'PropertyCard',
  components: {
    PropertyImageRow,
    ProspectBookViewing,
    ProspectFeedback,
    ProspectOffer,
    ProspectViewOffer
  },
  created() {
    if(this.selectedProperty) {
      this.checkDiaryBookings()
      this.checkPropertyOffers()
      this.getPropertyPhotos()
    }
  },
  data() {
    return {
      loading: false,
      // Property
      propertyDetailsDialog: false,
      carouselDialog: false,
      propertyPhotos: '',
      // Viewing
      viewingDetails: '',
      viewingDialog: false,
      upcomingViewingsDetails: '',
      availableTimes: '',
      // Feedback
      feedbackDialog: false,
      feedbackSent: false,
      // Offer
      existingOffers: '',
      viewOfferDialog: false,
      offerDialog: false,
    }
  },
  computed: {
    ...mapState({
      selectedPersonAccount: state => state.selectedPersonAccount,
      selectedProperty: state => state.selectedProperty,
      clientName: state => state.selectedPersonAccount.companyShortname,
      personOID: state => state.selectedPersonAccount.personOID,
      propertyID: state => state.selectedProperty.OID,
    }),
    latestViewings() { // returns array of viewings occured during last 48 hours
      const nowDateTime = Date.now()
      const fortyEightHours = 172800000
      const recentViewing = this.viewingDetails.filter( appointment =>{
        let milliSecondsSinceViewing = nowDateTime - Date.parse(appointment.End)
        if(milliSecondsSinceViewing > 0 && milliSecondsSinceViewing < fortyEightHours) return appointment
      })
      return recentViewing
    },
    cardColor() {
      return 'tertiaryDark'
    }
  },
  methods: {
    linkToMaps() {
      if(this.selectedPersonAccount.personType === 'Applicant Tenant') {
        let url = `https://www.google.com/maps/place/${this.selectedProperty.TenancyProperty.Latitude},${this.selectedProperty.TenancyProperty.Longitude}`;
        window.open(url, "_blank");
      }
      if(this.selectedPersonAccount.personType === 'Applicant Buyer') {
        let url = `https://www.google.com/maps/place/${this.selectedProperty.Latitude},${this.selectedProperty.Longitude}`;
        window.open(url, "_blank");
      }
    },
    descriptionText(val) {
      const slicedSytring = val.slice(0, 250)
      if(val.length <= 250) return val
      return slicedSytring + "...";
    },
    viewPropertyDetails() {
      this.$store.commit('SET_PROPERTY_TO_VIEW_DETAILS', this.selectedProperty)
      this.$router.push({ path: `/property-details/${this.selectedPersonAccount.companyShortname}/${this.selectedProperty.OID}` })
    }, 
    getPropertyPhotos() {
      if(this.selectedPersonAccount.personType === 'Applicant Tenant') {
        API.get('agentSignMainRest', `/get?query=/${this.clientName}/${this.personOID}/tenancies/${this.propertyID}/photos`)
          .then(res => {
            this.propertyPhotos = res.data.Data
          })
      }
      if(this.selectedPersonAccount.personType === 'Applicant Buyer') {
        API.get('agentSignMainRest', `/get?query=/${this.clientName}/${this.personOID}/sales/${this.propertyID}/photos`)
          .then(res => {
            this.propertyPhotos = res.data.Data
          })
      }
    },
    openViewingsDialog() {
      this.loading = true
      if(!this.availableTimes.length) {
        this.checkAvailableTimes()
      } else {
        this.viewingDialog = true
        this.loading = false
      }
    },
    checkDiaryBookings() {
      API.get('agentSignMainRest', `/get?query=/${this.clientName}/diary/${this.personOID}/appointments/${this.propertyID}`)
        .then(res => {
          if(res.status === '200') {
            // console.log('Booking details: ', res)
            const viewingDetails = res.data.Data
            if(viewingDetails.length) {
              this.viewingDetails = viewingDetails
              this.checkForUpcomingViewings()
            } else { 
                this.viewingDetails = []
                this.upcomingViewingsDetails = ''
              }
          }
          else {
            this.viewingDetails = []
            this.upcomingViewingsDetails = ''
          }
        })
    },
    makeOffer() {
      this.$store.commit('SET_PROPERTY_MAKE_OFFER', this.selectedProperty)
      this.offerDialog = true
    },
    viewOffer() {
      this.$store.commit('SET_PROPERTY_VIEW_OFFER', this.selectedProperty)
      this.viewOfferDialog = true
    },
    checkPropertyOffers() {
      if(this.selectedPersonAccount.personType === 'Applicant Tenant') {
        API.get('agentSignMainRest', `/get?query=/${this.clientName}/${this.personOID}/tenancies/${this.propertyID}/offers`)
          .then(res => {
              if(res.data.Data.length) {
                // console.log('Existing offer: ', res.data.Data)
                this.existingOffers = res.data.Data
              }
          })
      }
      if(this.selectedPersonAccount.personType === 'Applicant Buyer') {
        API.get('agentSignMainRest', `/get?query=/${this.clientName}/${this.personOID}/sales/${this.propertyID}/offers`)
          .then(res => {
              if(res.data.Data.length) {
                console.log('Existing offer: ', res.data)
                this.existingOffers = res.data.Data
              }
          })
      }
    },
    checkForUpcomingViewings() {
      this.viewingDetails.forEach(viewing => {
        const date = new Date()
        const jsonDate = JSON.stringify(date)
        const todaysDate = JSON.parse(jsonDate)
        const upcomingViewings = []

        if(viewing.End > todaysDate) {
          upcomingViewings.push(viewing)
          this.upcomingViewingsDetails = upcomingViewings
        } 

      })
    },
    checkAvailableTimes() {
      const preferredDate = moment().add(1, "days").format("YYYY-MM-DD") // this works for tomorrow`s date

      API.get('agentSignMainRest', `/get?query=/${this.clientName}/diary/${this.personOID}/appointments/allocations/0/1000?preferredDate=${preferredDate}`)
        .then(res => {
          if(res.data.Data.length) {
            const returnedArray = res.data.Data
            const availableTimes = returnedArray.map( obj => {
              const formattedStart = moment(obj.Start).format("YYYY-MM-DD hh:mm")
              const formattedEnd = moment(obj.End).format("YYYY-MM-DD hh:mm")
              return {
                Start: formattedStart,
                End: formattedEnd,
                StaffID: obj.StaffID,
                StaffName: obj.StaffName,
              }
            })
            this.availableTimes = availableTimes
            this.viewingDialog = true
            this.loading = false
          } else {
            // NB: need to inform User no available slots
            console.log('No available time slots!!!')
            this.loading = false
          }
        })
    },
    cancelViewing() {
      this.loading = true
      const appointmentID = this.upcomingViewingsDetails[0].OID

      API.del('agentSignMainRest', `/delete?query=/${this.clientName}/diary/${this.personOID}/appointments/${appointmentID}`)
        .then(res => {
          this.loading = false
          if(res.status === '200') {
            this.upcomingViewingsDetails.pop()
            this.checkDiaryBookings()
            this.$emit('viewingCancelled')
          }
        })
    },
    closeDialog() {
      this.viewingDialog = false
    },
    appointmentBooked() {
      this.closeDialog()
      this.checkDiaryBookings()
      this.$emit('appointmentBooked')
    },
    cancelOffer() {
      this.offerDialog = false
      this.checkPropertyOffers()
    },
    closeUpdateViewOffer() {
      this.viewOfferDialog = false
      this.checkPropertyOffers()
    }
  },
  watch: {
    selectedProperty(oldVal, newVal) {
      if(oldVal !== newVal) {
        this.checkDiaryBookings()
        this.checkPropertyOffers()
        this.getPropertyPhotos()
      }
    }
  },
  filters: {
    formatDate(val) {
      return moment(val).format('DD/MM/YYYY')
    },
    formatDateTime(val) {
      return moment(val).format('dddd Do MMMM, hh:mm a')
    },
    fromNow(val) {
      return moment(val).fromNow()
    }
  }
}
</script>
