<template>
  <div>
    <v-card
      v-if="!feedbackSent"
      class="mb-5 pb-1 lighten-5"
      flat 
      outlined
      :loading="loading"
    >
      <v-card-text class="d-flex flex-wrap align-start">
        <div v-if="selectedPersonAccount.personType === 'Applicant Tenant'">
          <p class="text-body-1 text-xl-h5 my-0 font-weight-bold">{{ propertyDetails.TenancyProperty.AddressNumber }} {{ propertyDetails.TenancyProperty.Address1 }}</p>
          <p class="text-body-2 text-xl-body-1 mb-0 my-0">{{ propertyDetails.TenancyProperty.Postcode }}</p>
        </div>
        <div v-if="selectedPersonAccount.personType === 'Applicant Buyer'">
          <p class="text-body-1 text-xl-h5 my-0 font-weight-medium">{{ propertyDetails.AddressNumber }} {{ propertyDetails.Address1 }}</p>
          <p class="text-body-2 text-xl-body-1 mb-0 my-0">{{ propertyDetails.Postcode }}</p>
        </div>
        <v-spacer></v-spacer>
        <div v-if="!feedbackError">
          <v-menu offset-y left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="secondary lighten-4"
                class="mr-5"
                dark
                fab
                outlined
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-thumb-down</v-icon>
              </v-btn>
            </template>
            <v-list class="py-0">
              <v-list-item class="secondary lighten-4">
                <v-list-item-title class="white--text">Message to landlord</v-list-item-title>
              </v-list-item>                    
              <v-list-item 
                v-for="(msg, i) in thumbsDownMsgs" :key="i"
                @click="sendAutoFeedback(msg)">
                <v-list-item-title>{{ msg }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="textEditor = true">
                <v-list-item-title class="secondary--text text--lighten-4">Write my own message
                  <v-icon color="secondary lighten-4" right small>mdi-pencil</v-icon>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu offset-y left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="tertiary"
                dark
                fab
                outlined
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-thumb-up</v-icon>
              </v-btn>
            </template>
            <v-list class="py-0">
              <v-list-item class="tertiary">
                <v-list-item-title class="white--text">Message to landlord</v-list-item-title>
              </v-list-item> 
              <v-list-item 
                v-for="(msg, i) in thumbsUpMsgs" :key="i"
                @click="sendAutoFeedback(msg)">
                <v-list-item-title>{{ msg }}</v-list-item-title>
              </v-list-item>                   
              <v-list-item @click="textEditor = true">
                <v-list-item-title class="tertiary--text">Write my own message
                  <v-icon color="tertiary" right small>mdi-pencil</v-icon>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div v-if="feedbackError">
          <span class="red--text">Error: Could not submit feedback</span>
        </div>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="textEditor"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title class="primary">
          <span v-if="selectedPersonAccount.personType === 'Applicant Tenant'" class="text-h5 white--text">{{ propertyDetails.TenancyProperty.AddressNumber }} {{ propertyDetails.TenancyProperty.Address1 }}</span>
          <span v-if="selectedPersonAccount.personType === 'Applicant Buyer'" class="text-h5 white--text">{{ propertyDetails.AddressNumber }} {{ propertyDetails.Address1 }}</span>
          <v-spacer></v-spacer>
          <v-btn color="white" @click="textEditor = false" text>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-5">
          <v-textarea
            v-model="textEditorText"
            auto-grow
            outlined
            rows="3"
            color="primary"
            name="input-7-4"
            :placeholder="textEditorPlaceholder"
          ></v-textarea>
        </v-card-text>

        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn 
            @click="submitTextEditorText"
            :disabled="!textEditorText.length"
            depressed 
            color="primary" 
            width="200"
          >
            <span class="white--text">Send feedback</span>
          </v-btn>
          <!-- <v-btn @click="closeDialog" depressed color="secondary" width="200">
            <span class="white--text">Close</span>
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { API } from 'aws-amplify'


export default {
  name: 'propertyFeedbackAlertCard',
  props: {
    propertyDetails: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      selectedPersonAccount: state => state.selectedPersonAccount,
      clientName: state => state.selectedPersonAccount.companyShortname,
      personOID: state => state.selectedPersonAccount.personOID,
    }),
    textEditorPlaceholder() {
      if(this.selectedPersonAccount.personType === 'Applicant Tenant') return 'Let the landlord know what you thought of the property.'
      if(this.selectedPersonAccount.personType === 'Applicant Buyer') return 'Let the home owner know what you thought of the property.'
      return ''
    }
  },
  data() {
    return {
      loading: false, 
      textEditor: false,
      feedbackSent: false,
      feedbackError: false,
      textEditorText: '',
      thumbsDownMsgs: [
        'Not what I was looking for.',
        'It was too small.',
        'The location wasn`t quite right.'
      ],
      thumbsUpMsgs: [
        'I want to make an offer.',
        'I`m considering making an offer.',
        'Nice, but not quite right for me.'
      ]
    }
  },
  methods: {
    sendAutoFeedback(msg) {
      // if(this.selectedPersonAccount.personType === 'Applicant Tenant') {
      //   this.sendFeedback( this.propertyDetails.TenancyProperty.OID ,msg)
      // }
      // if(this.selectedPersonAccount.personType === 'Applicant Buyer') {
      //   this.sendFeedback( this.propertyDetails.OID ,msg)
      // }
      this.sendFeedback( this.propertyDetails.OID ,msg)

    },
    submitTextEditorText() {
      this.textEditor = false
      // if(this.selectedPersonAccount.personType === 'Applicant Tenant') {
      //   this.sendFeedback( this.propertyDetails.TenancyProperty.OID , this.textEditorText)
      // }
      // if(this.selectedPersonAccount.personType === 'Applicant Buyer') {
      //   this.sendFeedback( this.propertyDetails.OID , this.textEditorText)
      // }
      this.sendFeedback( this.propertyDetails.OID , this.textEditorText)

    },
    sendFeedback(propertyOID, msg) {
      this.loading = 'primary' 
      
      const submissionModel = {
        PropertyID: propertyOID,
        Feedback: msg
      }
      const postParams = {
        body: submissionModel
      }

      API.post('agentSignMainRest', `/post?query=/${this.clientName}/diary/${this.personOID}/appointments/feedback`, postParams)
        .then(res => {
          console.log('Feedback summission res: ', res)
          if(res.status === '200') {
            this.loading = false
            this.feedbackSent = true
            this.$emit('refreshFeedbackAlert', propertyOID)
          } else {
            this.loading = false
            this.feedbackError = true
          }
        })
    },
  },
  beforeDestroy() {
    this.feedbackSent = false
    this.textEditorText = ''
  }
}
</script>

<style>

</style>