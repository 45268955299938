<template>
  <div>
      <v-card class="pb-3">
        <v-card-title class="primary">
          <span class="text-h5 white--text">{{ propertyMakeOffer.TenancyProperty.AddressNumber }} {{ propertyMakeOffer.TenancyProperty.Address1 }}</span>
          <v-spacer></v-spacer>
          <v-btn @click="cancelOffer" color="white" text>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-6 pb-0">
          <div v-if="offerSubmitionStatus">
            <p v-if="offerSubmitionStatus === '200'" class="green--text text-h6">Offer submitted successfully.</p>
            <p v-if="offerSubmitionStatus !== '200'" class="red--text text-h6">Error submitting offer. Try again.</p>
          </div>
          <v-form 
            v-if="!offerSubmitionStatus"
            v-model="valid"
          >    
            <v-row no-gutters>
              <v-col class="" cols="12">
                <v-row>
                  <v-col class="d-flex justify-start" cols="12" sm="4">
                    <span class="mt-1 text-h6">Rent</span>
                  </v-col>
                  <v-col class="mt-n5 mt-sm-0" cols="12" sm="8">
                    <v-text-field
                      dense
                      v-model="rent"
                      outlined
                      prefix="£"
                      id="rent"
                      :rules="rentRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="" cols="12">
                <v-row>
                  <v-col class="d-flex justify-start" cols="12" sm="4">
                    <span class="mt-1 text-h6">No. of tenants</span>
                  </v-col>
                  <v-col class="mt-n5 mt-sm-0" cols="12" sm="8">
                    <v-select
                      dense
                      outlined
                      :items="tenants"
                      v-model.number="totalTenants"
                      :rules="tenantRules"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="" cols="12">
                <v-row>
                  <v-col class="d-flex justify-start" cols="12" sm="4">
                    <span class="mt-1 text-h6">My status</span>
                  </v-col>
                  <v-col class="mt-n5 mt-sm-0" cols="12" sm="8">
                    <v-select
                      dense
                      outlined
                      :items="statuses"
                      v-model="myStatus"
                      :rules="statusRules"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="" cols="12">
                <v-row>
                  <v-col class="d-flex justify-start" cols="12" sm="4">
                    <span class="mt-1 text-h6">My annual income</span>
                  </v-col>
                  <v-col class="mt-n5 mt-sm-0" cols="12" sm="8">
                    <v-text-field
                      dense
                      v-model="myIncome"
                      outlined
                      prefix="£"
                      id="myIncome"
                      :rules="incomeRules"
                    ></v-text-field>
                    <!-- <v-currency-field 
                      dense
                      v-model.number="myIncome"
                      outlined
                      id="myIncome"
                      :rules="incomeRules"
                      prefix="£"
                    /> -->
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="" cols="12">
                <v-row>
                  <v-col class="d-flex justify-start" cols="12" sm="4">
                    <span class="mt-1 text-h6">Like to move in by:</span>
                  </v-col>
                  <v-col class="mt-n5 mt-sm-0" cols="12" sm="8">
                    <v-menu
                      v-model="moveInDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          dense
                          outlined
                          v-model="computedDateFormatted"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :rules="dateRules"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="moveInByDate"
                        @input="moveInDateMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="" cols="12">
                <v-row>
                  <v-col class="d-flex justify-start" cols="12" sm="4">
                    <span class="mt-1 text-h6">Children</span>
                  </v-col>
                  <v-col class="mt-n5 mt-sm-0" cols="12" sm="8">
                    <v-select
                      dense
                      outlined
                      :items="children"
                      v-model="amountChildren"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="" cols="12">
                <v-row align="center">
                  <v-col class="d-flex justify-start" cols="12" sm="4">
                    <span class="mt-1 text-h6">Other</span>
                  </v-col>
                  <v-col class="mt-n5 mt-sm-0 d-flex justify-start" cols="12" sm="8">
                    <v-switch inset label="Pets" v-model="pets"></v-switch>
                    <v-switch class="ml-12" inset label="Smoker" v-model="smoker"></v-switch>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="mt-2" cols="12">
                <v-row>
                  <v-col class="d-flex justify-start" cols="12" sm="4">
                    <span class="mt-1 text-h6">Requirements</span>
                  </v-col>
                  <v-col class="mt-n5 mt-sm-0" cols="12" sm="8">
                    <v-textarea
                      v-model="requirements" 
                      dense
                      placeholder="Enter any requirements here..."
                      auto-grow
                      outlined
                      rows="2"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form> 
        </v-card-text>
        <v-card-actions 
          v-if="!offerSubmitionStatus"
          class="px-4"
        >
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!valid"
            @click="confirmOffer" 
            block
            depressed 
            color="primary"
            x-large 
          >
            <span class="white--text">Continue</span>
          </v-btn>
        </v-card-actions>
      </v-card>

    <v-dialog
      v-model="confirmOfferDialog"
      max-width="500px"
      transition="dialog-transition"
      scrollable
      persistent
    >
      <v-card>
        <v-card-title class="primary darken-4">
          <span class="white--text text-h6">Confirm and submit</span>
          <v-spacer></v-spacer>
          <v-btn @click="confirmOfferDialog = false" color="white" outlined>Edit</v-btn>
        </v-card-title>
        <v-card-text class="">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    <!-- empty -->
                  </th>
                  <th class="text-left">
                    <!-- empty -->
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Rent</td>
                  <td><span class="font-weight-bold">£{{ rent }}</span></td>
                </tr>
                <tr>
                  <td>Number of tenants</td>
                  <td><span class="font-weight-bold">{{ totalTenants }}</span></td>
                </tr>
                <tr>
                  <td>My status</td>
                  <td><span class="font-weight-bold">{{ myStatus }}</span></td>
                </tr>
                <tr>
                  <td>Annual income</td>
                  <td><span class="font-weight-bold">£{{ myIncome }}</span></td>
                </tr>
                <tr>
                  <td>Move in date</td>
                  <td><span class="font-weight-bold">{{ moveInByDate }}</span></td>
                </tr>
                <tr>
                  <td>Children</td>
                  <td><span class="font-weight-bold">{{ amountChildren }}</span></td>
                </tr>
                <tr>
                  <td>Pets</td>
                  <td><span class="font-weight-bold">{{ pets }}</span></td>
                </tr>
                <tr>
                  <td>Smoker</td>
                  <td><span class="font-weight-bold">{{ smoker }}</span></td>
                </tr>
                <tr>
                  <td>Requirements</td>
                  <td><span class="font-weight-bold">{{ requirements }}</span></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-btn
            @click="submitOffer"
            :loading="loading"
            class="mt-5"
            depressed 
            color="primary"
            x-large 
            block
          >
            <span class="white--text">Submit offer</span>
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog> 
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { API } from 'aws-amplify'

export default {
  name: 'tenancyOfferCard',
  created() {
    this.rent = this.propertyMakeOffer.RentAdvertised
  },
  data() {
    return {
      valid: false, // form validation
      loading: false,
      statuses: [
        'Single', 'Married', 'Student', 'Unemployed', 'Self employed', 'other' 
      ],
      myStatus: '',
      myIncome: '',
      moveInByDate: null,
      moveInDateMenu: false,
      tenants: [1, 2, 3, 4, 5, 6, 7, 8],
      totalTenants: '',
      children: [ 'none', 1, 2, 3, 4, 5, 6],
      amountChildren: '',
      rent: '',
      pets: false,
      smoker: false,
      requirements: '',
      confirmOfferDialog: false,
      offerSubmitionStatus: '',
      // validation rules
      statusRules: [
        v => !!v || 'Select a status'
      ],
      incomeRules: [
        v => !!v || 'Please enter your annual income'
      ],
      dateRules: [
        v => !!v || 'Please select a move in by date'
      ],
      tenantRules: [
        v => !!v || 'Please state total number of tenants'
      ],
      rentRules: [
        v => !!v || 'Please enter a valid rent amount'
      ],
    }
  },
  computed: {
    ...mapState({
      selectedPersonAccount: state => state.selectedPersonAccount,
      clientName: state => state.selectedPersonAccount.companyShortname,
      personOID: state => state.selectedPersonAccount.personOID,
      propertyMakeOffer: state => state.propertyMakeOffer,
    }),
    computedDateFormatted () {
      return this.formatDate(this.moveInByDate)
    },
    rentPriceToNumber() {
      if(this.rent) {
        let noCommas = this.rent.replaceAll(",", "")
        return parseInt(noCommas)
      } return null
    },
    advertisedRent() {
      const formattedString = this.propertyMakeOffer.RentAdvertised.toLocaleString('en-UK', { style: 'currency', currency: 'GBP' })
      const formattedPrice = formattedString.slice(0, -3)
      return `Advertise for £${formattedPrice}`
    }
  },
  methods: {
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    confirmOffer() {
      this.confirmOfferDialog = true
    },
    submitOffer() {
      this.loading = true

      const offerNotes = `
        Tenant status: ${this.myStatus}, /n 
        Tenant income: £${this.myIncome}, /n
        Move in date: ${this.moveInByDate}, /n
        Children: ${this.amountChildren}, /n
        Pets: ${this.pets}, /n
        Smoker: ${this.smoker}, /n
        Requirements: ${this.requirements}
      `

      const submissionModel = {
        Amount: this.rent,
        MaximumAmount: this.rent,
        NoOfTenants: this.totalTenants,
        Notes: offerNotes
      }

      const postParams = {
        body: submissionModel
      }

      API.post('agentSignMainRest', `/post?query=/${this.clientName}/${this.personOID}/tenancies/${this.propertyMakeOffer.OID}/offers`, postParams)
        .then(res => {
          if(res.status === '200') {
            console.log('Tenancy offer submition response: ', res)
            this.loading = false
            this.offerSubmitionStatus = res.status
            this.confirmOfferDialog = false
          } else {
            // inform user of error
            this.loading = false
            this.offerSubmitionStatus = res.status
            this.confirmOfferDialog = false
          }
        })

    },
    cancelOffer() {
      this.myStatus = '',
      this.myIncome = '',
      this.moveInByDate = null,
      this.moveInDateMenu = false,
      this.totalTenants = '',
      this.amountChildren = '',
      this.rent = '',
      this.pets = false,
      this.smoker = false,
      this.requirements = '',
      this.offerSubmitionStatus = ''
      this.$emit('cancelOffer') // emits to ProspectOffer.vue
    }
  },
  watch: {
    rent(newValue) {
      if(newValue.length > 3) { // added .length to solve error caused by setting rent on created()
        const result = newValue.replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.$nextTick(() => this.rent = result);
      }
    },
    myIncome(newValue) {
      if(newValue.length > 3) { // added .length to solve error caused by setting rent on created()
        const result = newValue.replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.$nextTick(() => this.myIncome = result);
      }
    },
  }
  
}
</script>

<style>

</style>