<template>
  <div>
    <v-container fluid class="pa-0">
      
      <SearchToolbar ref="searchToolbar" class="sticky-search-bar" /> 

      <LoadingBounce class="mt-16" v-if="showPageLoader" />

      <!-- TEST COMPONENT -->
      <!-- <CurrencyTextfield
        class="ma-5" 
        dense 
        outlined 
        @setAmountToNumber="testCurrencyField"
      /> -->
      
      <v-row v-if="!showPageLoader" no-gutters class="pt-3 pt-lg-5">
        <v-col class="left-col px-3" cols="12" lg="9">
          <v-row>
            <transition name="fade-left">
              <!-- Mobile only -->
              <v-col class=" px-md-10 d-lg-none" cols="12">
                <v-card flat class="rounded-sm">
                  <v-card-title class="secondary white--text py-3">
                    <span class="font-weight-light">{{ selectedPersonAccount.companyName }}</span>
                    <v-spacer></v-spacer>
                    <v-btn 
                      @click="rightDrawer = !rightDrawer" 
                      color="primary" 
                      depressed
                      class="rounded-sm"
                    >
                      <span class="secondary--text custom-transform-class text-none">My shortlist</span>
                      <v-icon color="secondary" small right>mdi-home-city-outline</v-icon>
                    </v-btn>
                  </v-card-title> 
                </v-card>
              </v-col>
            </transition>

            <v-col class="px-3 px-md-10" cols="12">
              <transition name="slide-fade-right">
                <PropertyCard
                  v-if="hidePropertyCard" 
                  class="mt-n3 mt-lg-3" 
                  @appointmentBooked="getAllAppointments"
                  @viewingCancelled="getAllAppointments"
                />
              </transition>
              <v-card 
                v-if="!selectedProperty"
                flat
                class="mt-n3 mt-lg-11" 
              >
                <v-card-text>
                  <p class="text-h3 tertiary--text">No properties selected</p>
                  <p class="text-body-1">Search and add properties to you short list</p>
                </v-card-text>
              </v-card>
            </v-col>

            <!-- Search results -->
            <transition name="fade-up">
              <v-col v-if="advertisedTenancies || advertisedSales"  class="mt-5 px-3 px-md-10" cols="12">
                <v-card 
                  flat 
                  class="grey lighten-2 mb-3 rounded-sm"
                  min-height="750"
                >
                  <v-card-title class="d-flex py-3 px-5 px-md-10 d-block py-1">
                    <div class="tertiaryDark--text">
                      <span v-if="selectedPersonAccount.personType === 'Applicant Tenant'" class="text-h5 font-weight-medium">{{ advertisedTenancies.Count }}</span>
                      <span v-if="selectedPersonAccount.personType === 'Applicant Buyer'" class="text-h5 font-weight-medium">{{ advertisedSales.Count }}</span>
                      <span
                        v-if="advertisedTenancies.Count > 1 || advertisedSales.Count > 1"
                        class="text-h5 ml-1 mt-1 font-weight-medium">results</span>
                      <span v-if="advertisedTenancies.Count === 1 || advertisedSales.Count === 1" class="text-h5 ml-1 mt-1 font-weight-medium">result</span>
                      <span v-else class="text-h5 ml-1 mt-1 font-weight-medium">results</span>
                    </div>
                    <v-spacer></v-spacer>
                    <span class="font-weight-light text-body-1 mr-n1">Search by</span>
                    <div class="mr-n3">
                      <v-img
                        @click="toAgentOS"
                        alt="Application logo"
                        class="cursor-pointer"
                        contain
                        src="../assets/AgentOS-Logo.png"
                        width="80"
                      />
                    </div>
                  </v-card-title>
                  <v-card-text class="px-5 px-md-10">
                    <PropertySearchResultCard
                      v-scroll="onScroll"  
                      v-for="property in advertisedProperties" :key="property.OID"
                      @updateSavedFavourites="listFavourites"  
                      @appointmentBooked="getAllAppointments"
                      @viewingCancelled="getAllAppointments"
                      :propertyDetails="property"
                      class="mb-5 mb-md-10" 
                    />

                    <div 
                      v-if="!advertisedProperties.length"
                      class="text-center"
                    >
                      <p class="text-h4">Your search returned no properties.</p>
                      <p class="grey--text text-body-1">Try tweaking you search parametres to find relevant properties.</p>
                      <!-- <v-icon color="grey darken-1" size="200">mdi-crosshairs</v-icon> -->
                    </div>


                    
                    <!-- <div class="text-center">
                      <v-pagination
                        :length="6"
                      ></v-pagination>
                    </div> -->
                    
                  </v-card-text>
                </v-card>
              </v-col>
            </transition>
          </v-row>

          <v-fab-transition>
            <v-btn
              v-show="showToTopBtn"
              @click="toTop"
              color="agentPrimary"
              fab
              dark
              fixed
              bottom right
              class="mb-16 mb-md-3"
            >
              <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
          </v-fab-transition>
        </v-col>

        <v-col class="d-none d-lg-block right-col pr-md-10 pt-3" cols="12" lg="3" key="1">
          <div class="mb-8">
            <transition appear name="slide-fade-left">  
              <AgencyDetailsCard key="1" />
            </transition>
            <transition appear name="slide-fade-left-delay">  
              <v-card v-if="favourites || linkedProperties" flat class="mt-8 grey lighten-2 rounded-sm">
                <v-card-title class="d-flex align-start">
                  <div>
                    <p class="tertiaryDark--text text-h5 font-weight-regular ma-0">My shortlist</p>
                    <p class="tertiaryDark--text text-caption font-weight-regular ma-0">{{ linkedAndFavProperties.length }} properties</p>
                  </div>
                  <v-spacer></v-spacer>
                  <v-btn @click="showFavList = !showFavList" fab small text class="pr-0">
                    <v-icon v-if="showFavList" color="tertiaryDark">mdi-star-box-multiple-outline</v-icon>
                    <v-icon v-else color="tertiaryDark">mdi-eye-off-outline</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text v-if="showFavList">
                  <FavPropertyList
                    v-if="favourites"
                    @updateSavedFavourites="listFavourites"  
                    :linkedAndFavProperties="linkedAndFavProperties"
                  />
                </v-card-text>
              </v-card>
              </transition>
            </div>
            <transition appear name="fade-in">  
              <RightMoveSearch class="sticky" />
            </transition>
        </v-col>
        
        <v-footer 
          v-if="selectedProperty"
          fixed 
          class="d-md-none secondary py-3 d-flex justify-space-between"
        >
          <v-btn 
            :href="`tel:${agentContactNumber}`"
            width="48%" 
            depressed
            class="py-3 rounded-sm" 
            color="primary"
            :large="$vuetify.breakpoint.smAndUp"
          ><span class="secondary--text custom-transform-class text-none">Call agent</span></v-btn>
          <v-btn
            :href="`mailto:${selectedProperty.AgencyEmail}`"
            width="48%" 
            depressed
            class="py-3 rounded-sm" 
            color="primary"
            :large="$vuetify.breakpoint.smAndUp"
          ><span class="secondary--text custom-transform-class text-none">Email agent</span></v-btn>
        </v-footer>
      </v-row>

    </v-container>

    <v-navigation-drawer
      width="75%"
      v-model="rightDrawer"
      right
      temporary
      app
    >
      <v-card flat>
        <div class="pa-5 d-flex flex-column right-drawer-div">
          <AgencyDetailsCard />
          <v-card v-if="favourites || linkedProperties" flat class="mt-5 grey lighten-2">
            <v-card-title class="pb-0 d-flex align-start">
              <div>
                <p class="tertiaryDark--text text-h5 font-weight-regular ma-0">My shortlist</p>
                <p class="tertiaryDark--text text-caption font-weight-regular ma-0">{{ linkedAndFavProperties.length }} properties</p>
              </div>
              <v-spacer></v-spacer>
              <v-icon color="tertiaryDark">mdi-star-box-multiple-outline</v-icon>
            </v-card-title>
            <v-card-text >
              <FavPropertyList
                v-if="favourites"
                @updateSavedFavourites="listFavourites" 
                @propertySelected="rightDrawer = false" 
                :linkedAndFavProperties="linkedAndFavProperties"
                class="mt-5" 
              />
            </v-card-text>
          </v-card>
          <div class="mt-auto mx-auto">
            <v-btn @click="rightDrawer = false" color="tertiary" text>Close</v-btn>
          </div>
        </div>
      </v-card>
    </v-navigation-drawer>

    <v-dialog
      v-model="feedbackAlertDialog"
      max-width="600px"
      transition="dialog-transition"
      persistent
      :fullscreen="$vuetify.breakpoint.xsOnly"
      scrollable
    >
      <v-card class="pb-1">
        <v-card-title class="agentPrimary white--text py-1">
          <span class="font-weight-light">Recently viewed</span>
          <v-spacer></v-spacer>
          <v-btn @click="feedbackAlertDialog = false" color="white" fab text>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-3">
          <p class="text-h6 mt-0 mb-5">Hey, what did you think these properties?</p>
          <PropertyFeedbackAlertCard 
            v-for="property in propertiesWithRecentViewingsDetails" 
            :key="property.OID"  
            :propertyDetails="property"
            @refreshFeedbackAlert="refreshAccountViewings"
          />

          <!-- <div v-for="viewing in recentViewings" :key="viewing.OID">
            <v-card
              v-if="!viewing.Feedback.length"
              class="red pa-3 my-3"
            >
              {{ viewing }}
            </v-card>
          </div> -->
        </v-card-text>
      </v-card>
    </v-dialog>
      

  </div>
</template>

<script>
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
import { API, graphqlOperation  } from 'aws-amplify'
import { listFavouritePropertys } from '../graphql/queries'
import moment from 'moment/src/moment'

import PropertyCard from '@/components/property/PropertyCard.vue';
import PropertySearchResultCard from '@/components/property/PropertySearchResultCard.vue';
import LoadingBounce from '@/components/reusableComponents/LoadingBounce.vue';
import RightMoveSearch from '@/components/searches/RightMoveSearch.vue';
import SearchToolbar from '@/components/searches/SearchToolbar.vue';
import FavPropertyList from '@/components/property/FavPropertyList.vue';
import AgencyDetailsCard from '@/components/agency/AgencyDetailsCard.vue';
import PropertyFeedbackAlertCard from '@/components/property/PropertyFeedbackAlertCard.vue';

// test
// import CurrencyTextfield from '@/components/reusableComponents/CurrencyTextfield.vue';

export default {
  name: 'property-view',
  components: {
    PropertyCard,
    PropertySearchResultCard,
    LoadingBounce,
    RightMoveSearch,
    SearchToolbar,
    FavPropertyList,
    AgencyDetailsCard,
    PropertyFeedbackAlertCard
    // CurrencyTextfield
  },
  data() {
    return {
      showPageLoader: false,
      hidePropertyCard: true,
      searchedProperties: '',
      rightDrawer: false,
      showToTopBtn: false,
      showFavList: true,
      // Search
      searchCity: 'cardiff',
      searchPostcode: '',
      searchMinBed: '',
      searchMaxBed: '',
      searchMinPrice: 300,
      searchMaxPrice: 1000,
      searchPropertyType: '',
      // Favourites
      favFullDetailsArray: '',
      //Other
      feedbackAlertDialog: false,
      feedbackSentPropertyIDs: []
    }
  },
  created() {
    this.getAllAppointments() // for personAccount
    this.checkPersonType() 
    this.listFavourites()
  },
  computed: {
    ...mapState({
      selectedPersonAccount: state => state.selectedPersonAccount,
      selectedProperty: state => state.selectedProperty,
      clientName: state => state.selectedPersonAccount.companyShortname,
      personOID: state => state.selectedPersonAccount.personOID,
      favourites: state => state.favouriteProperties,
      advertisedTenancies: state => state.advertisedTenancies,
      advertisedSales: state => state.advertisedSales,
      linkedProperties : state => state.linkedProperties,
      tenancySearchPreferences: state => state.tenancySearchPreferences,
      selectedAccountAppointments: state => state.selectedAccountAppointments
    }),
    noFavsNoLinkedProperties() {
      if(!this.linkedAndFavProperties.length) return true
      return false
    },
    agentContactNumber() {
      if(this.selectedProperty) {
        if(this.selectedPersonAccount.personType === 'Applicant Tenant') return this.selectedProperty.AgencyPhoneNumber
          return this.selectedProperty.AgencyNumber
      } return ''
    },
    ...mapGetters([
      'allMyproperties'
    ]),
    todaysDate() {
      return moment().format("Do MMMM YYYY")
    },
    linkedAndFavProperties() { // returns array of linked and favved properties
      let combinedArray = []
      if(this.linkedProperties) {
        combinedArray = [...this.favFullDetailsArray, ...this.linkedProperties]
      } else {
        combinedArray = [...this.favFullDetailsArray]
      }

      combinedArray.forEach((property, i) => {
        if(property === this.selectedProperty) { //move selected property to beginning of array
          combinedArray.splice(i, 1)
          combinedArray.unshift(property)
        }
      }) 
      // console.log('LINKED & FAVS: ', combinedArray)
      return combinedArray
    },
    advertisedProperties() { // properties returned from Search
      if(this.selectedPersonAccount.personType === 'Applicant Tenant') { return this.advertisedTenancies.Data}
      if(this.selectedPersonAccount.personType === 'Applicant Buyer') { return this.advertisedSales.Data}
      return []
    },
    recentViewings() { // last 48 hrs
      const nowDateTime = Date.now()
      const fortyEightHours = 172800000
      if(this.selectedAccountAppointments) {
        const recentViewing = this.selectedAccountAppointments.filter( appointment =>{
          let milliSecondsSinceViewing = nowDateTime - Date.parse(appointment.End)
          if(milliSecondsSinceViewing > 0 && milliSecondsSinceViewing < fortyEightHours) return appointment
        })
        return recentViewing
      } return []
    },
    recentViewingsNoFeedback() {
      return this.recentViewings.filter( viewing => {
        if(!viewing.Feedback || !viewing.Feedback.length) {
          return viewing
        }
      })
    },
    propertyIDsWithRecentViewings() { // return an array of IDs for properties with recent viewings (48hours)
      const propertyIDs = []
      this.recentViewingsNoFeedback.forEach( viewing => {
        propertyIDs.push(...viewing.PropertyIDs, ...viewing.SalesIDs) 
      })
      return propertyIDs
    },
    propertiesWithRecentViewingsDetails() { // array with fullPropertyDetails for properties with recent viewings (48 hours)
      const IDs = this.propertyIDsWithRecentViewings
      let tenancies = []
      let sales = []

      if(this.selectedPersonAccount.personType === 'Applicant Tenant') {
        tenancies = this.linkedAndFavProperties.filter( property => {
          const hasViewing = IDs.some(id => id === property.TenancyProperty.OID)
          if(hasViewing) return property
        })
      }
      if(this.selectedPersonAccount.personType === 'Applicant Buyer') {
        sales = this.linkedAndFavProperties.filter( property => {
          const hasViewing = IDs.some(id => id === property.OID)
          if(hasViewing) return property
        })
      }
      const propertyList = [...tenancies, ...sales] // full list
      return propertyList
    },
  },
  methods: {
    testCurrencyField(e) { // test method for a currency Component I created
      console.log('PROPERTY: ', e)
    },
    toTop() {
      this.$vuetify.goTo(0)
    },
    toAgentOS() {
      let url = "https://agentos.com/"
      window.open(url, "_blank");
    },
    onScroll(e) { // Renders the toTop button
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.showToTopBtn = top > 1000
    },
    checkPersonType() { // checks what account type app is set to and get linked tenancies
      if(!this.linkedProperties) {
        if(this.selectedPersonAccount.personType === 'Applicant Tenant') {
          this.getLinkedTenancies()
        }  
        if(this.selectedPersonAccount.personType === 'Applicant Buyer') {
          this.getLinkedSalesProperties()
        }
      }
    },
    listFavourites() { // gets array of favourite property IDs from DynamoDB
      API.graphql(graphqlOperation(listFavouritePropertys, {limit: 100}))
        .then(res => {
          const apiData = res.data.listFavouritePropertys.items
          const linkedToAccount = []
          apiData.forEach( item => {
            if(item.personAccountID === this.selectedPersonAccount.personOID) {
              linkedToAccount.push(item)
            }
          })
          this.$store.commit('SET_FAV_PROPERTIES', linkedToAccount)

          if(this.favourites.length) {
            this.getFavsFullDetails()
          } else {
            this.favFullDetailsArray = ''
            if(!this.linkedProperties) {
              console.log('NB: no more linkedfavvedProperties')
              this.setSelectedProperty()
            }
          }
        })
    },
    getFavsFullDetails() { // gets full details for list of favourite IDs
      const propertyArray = []
      if(this.selectedPersonAccount.personType === 'Applicant Tenant') {
        this.favourites.forEach(item => {
          API.get('agentSignMainRest', `/get?query=/${this.clientName}/${this.personOID}/tenancies/advertised/${item.propertyOID}`)
            .then(res => {
              // console.log('Favourite tenancies full details: ', res.data)
              propertyArray.push(res.data)
            })
          this.favFullDetailsArray = propertyArray
        })
      }
      if(this.selectedPersonAccount.personType === 'Applicant Buyer') {
        this.favourites.forEach(item => {
          API.get('agentSignMainRest', `/get?query=/${this.clientName}/${this.personOID}/sales/advertised/${item.propertyOID}`)
            .then(res => {
              // console.log('Favourite sales full details: ', res.data)
              propertyArray.push(res.data)
            })
          this.favFullDetailsArray = propertyArray
        })
      }
    },
    getLinkedTenancies() {
      this.showPageLoader = true
      API.get('agentSignMainRest', `/get?query=/${this.clientName}/${this.personOID}/tenancies`)
        .then(res => {
          // console.log('linkedTenancies ', res)
          if(res.data.Data.length) {
            this.setLinkedProperties(res.data.Data)
            this.setSelectedProperty(this.linkedProperties[0])
          }
          this.$refs.searchToolbar.getLettingsSearchPreferences()
          this.showPageLoader = false
        })
    },
    getLinkedSalesProperties() {
      this.showPageLoader = true
      API.get('agentSignMainRest', `/get?query=/${this.clientName}/${this.personOID}/sales`)
        .then(res => {
          this.setLinkedProperties(res.data.Data)
          this.setSelectedProperty(this.linkedProperties[0])
          this.$refs.searchToolbar.getSalesSearchPreferences()
          this.showPageLoader = false
        })
    },
    setSelectedProperty(property) { // set the selected property in app $store
      this.$store.commit('SET_SELECTED_PROPERTY', property)
    },
    setLinkedProperties(array) {
      this.$store.commit('SET_LINKED_PROPERTIES', array)
    },
    getAllAppointments() {
      API.get('agentSignMainRest', `/get?query=/${this.clientName}/diary/${this.personOID}/appointments`)
        .then(res => {
          console.log('Appointments response: ', res)
          this.$store.commit('SET_SELECTED_ACCOUNT_APPOINTMENTS', res.data.Data)
        })
    },
    openFeedbackAlertDialog() {
      if(this.propertiesWithRecentViewingsDetails.length) this.feedbackAlertDialog = true
    },
    refreshAccountViewings(propertyOID) {
      this.feedbackSentPropertyIDs.push(propertyOID)
      if(this.feedbackSentPropertyIDs.length === this.propertyIDsWithRecentViewings.length) { // if all feedback sent
        this.feedbackAlertDialog = false
        this.feedbackSentPropertyIDs = []
        this.getAllAppointments() // refresh accounts
      }
    }
  },
  watch: {
    selectedProperty(newVal, oldVal) { // rerenders selected property when one is selected in the favList component
      if(oldVal !== newVal) {
        this.hidePropertyCard = !this.hidePropertyCard
        setTimeout( () => {
          this.hidePropertyCard = !this.hidePropertyCard
        },550)
      }
    },
    selectedPersonAccount(newVal, oldVal) { // calls specific methods on change of personAccount
      if(newVal !== oldVal) {
        // console.log('New person account')
        if(this.selectedPersonAccount.personType === 'Applicant Tenant') {
          this.listFavourites()
          this.getLinkedTenancies()
        }  
        if(this.selectedPersonAccount.personType === 'Applicant Buyer') {
          this.listFavourites()
          this.getLinkedSalesProperties()
        }
      }
    },
    propertiesWithRecentViewingsDetails() { // fires off call to open feedbackAlertDialog 
      this.openFeedbackAlertDialog()
    },
  },
}
</script>

<style scoped>
/* Transitions ================================= */
/* ============================================= */

/* Slide fade on main property card */
.slide-fade-right-enter-active {
  transition: all 1s cubic-bezier(0.34, 1.56, 0.64, 1);
}
.slide-fade-right-leave-active {
  transition: all .5s cubic-bezier(0.36, 0, 0.66, -0.56);
}
.slide-fade-right-enter {
  transform: translateX(-150px);
  opacity: 0;
}
.slide-fade-right-leave-to {
  transform: translateY(-100px);
  opacity: 0;
}

/* Slide fade left on RIGHT col */
.slide-fade-left-enter-active {
  transition: all .5s cubic-bezier(.17,.67,.17,1.21);
}

.slide-fade-left-leave-active {
  transition: all .5s ease;
}

.slide-fade-left-enter,
.slide-fade-left-leave-to {
  transform: translateX(100px);
  opacity: 0;
}

/* Fav list delays transition */
.slide-fade-left-delay-enter-active {
  transition: all .8s cubic-bezier(.17,.67,.17,1.21);
}

.slide-fade-left-delay-leave-active {
  transition: all .8s ease;
}

.slide-fade-left-delay-enter,
.slide-fade-left-delay-leave-to {
  transform: translateX(200px);
  opacity: 0;
}

/* Fade in */
.fade-in-enter-active,
.fade-in-leave-active {
  transition: opacity 3s ease-in-out;
}
.fade-in-enter, 
.fade-in-leave-to {
  opacity: 0;
}

/* Fade up for Results */
.fade-up-enter-active,
.fade-up-leave-active {
  transition: all 1s cubic-bezier(.17,.67,.17,1.21);
}

.fade-up-leave-active {
  transition: all 1s ease;
}

.fade-up-enter, 
.fade-up-leave-to {
  transform: translateY(100px);
  opacity: 0;
}

/* ================================================ */
.right-drawer-div {
  height: 95vh;
  overflow: auto;
}

.sticky-search-bar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 70px;
}
</style>