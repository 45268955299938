/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPersonAccount = /* GraphQL */ `
  mutation CreatePersonAccount(
    $input: CreatePersonAccountInput!
    $condition: ModelPersonAccountConditionInput
  ) {
    createPersonAccount(input: $input, condition: $condition) {
      id
      companyName
      accountCreated
      displayInformation
      personName
      personOID
      companyShortname
      personType
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updatePersonAccount = /* GraphQL */ `
  mutation UpdatePersonAccount(
    $input: UpdatePersonAccountInput!
    $condition: ModelPersonAccountConditionInput
  ) {
    updatePersonAccount(input: $input, condition: $condition) {
      id
      companyName
      accountCreated
      displayInformation
      personName
      personOID
      companyShortname
      personType
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deletePersonAccount = /* GraphQL */ `
  mutation DeletePersonAccount(
    $input: DeletePersonAccountInput!
    $condition: ModelPersonAccountConditionInput
  ) {
    deletePersonAccount(input: $input, condition: $condition) {
      id
      companyName
      accountCreated
      displayInformation
      personName
      personOID
      companyShortname
      personType
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createPersonAccountSyncDateTime = /* GraphQL */ `
  mutation CreatePersonAccountSyncDateTime(
    $input: CreatePersonAccountSyncDateTimeInput!
    $condition: ModelPersonAccountSyncDateTimeConditionInput
  ) {
    createPersonAccountSyncDateTime(input: $input, condition: $condition) {
      id
      lastUpdate
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updatePersonAccountSyncDateTime = /* GraphQL */ `
  mutation UpdatePersonAccountSyncDateTime(
    $input: UpdatePersonAccountSyncDateTimeInput!
    $condition: ModelPersonAccountSyncDateTimeConditionInput
  ) {
    updatePersonAccountSyncDateTime(input: $input, condition: $condition) {
      id
      lastUpdate
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deletePersonAccountSyncDateTime = /* GraphQL */ `
  mutation DeletePersonAccountSyncDateTime(
    $input: DeletePersonAccountSyncDateTimeInput!
    $condition: ModelPersonAccountSyncDateTimeConditionInput
  ) {
    deletePersonAccountSyncDateTime(input: $input, condition: $condition) {
      id
      lastUpdate
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createFavouriteProperty = /* GraphQL */ `
  mutation CreateFavouriteProperty(
    $input: CreateFavouritePropertyInput!
    $condition: ModelFavouritePropertyConditionInput
  ) {
    createFavouriteProperty(input: $input, condition: $condition) {
      id
      propertyOID
      personAccountID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateFavouriteProperty = /* GraphQL */ `
  mutation UpdateFavouriteProperty(
    $input: UpdateFavouritePropertyInput!
    $condition: ModelFavouritePropertyConditionInput
  ) {
    updateFavouriteProperty(input: $input, condition: $condition) {
      id
      propertyOID
      personAccountID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteFavouriteProperty = /* GraphQL */ `
  mutation DeleteFavouriteProperty(
    $input: DeleteFavouritePropertyInput!
    $condition: ModelFavouritePropertyConditionInput
  ) {
    deleteFavouriteProperty(input: $input, condition: $condition) {
      id
      propertyOID
      personAccountID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createHiddenPersonAccountAgentSign = /* GraphQL */ `
  mutation CreateHiddenPersonAccountAgentSign(
    $input: CreateHiddenPersonAccountAgentSignInput!
    $condition: ModelHiddenPersonAccountAgentSignConditionInput
  ) {
    createHiddenPersonAccountAgentSign(input: $input, condition: $condition) {
      id
      personOID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateHiddenPersonAccountAgentSign = /* GraphQL */ `
  mutation UpdateHiddenPersonAccountAgentSign(
    $input: UpdateHiddenPersonAccountAgentSignInput!
    $condition: ModelHiddenPersonAccountAgentSignConditionInput
  ) {
    updateHiddenPersonAccountAgentSign(input: $input, condition: $condition) {
      id
      personOID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteHiddenPersonAccountAgentSign = /* GraphQL */ `
  mutation DeleteHiddenPersonAccountAgentSign(
    $input: DeleteHiddenPersonAccountAgentSignInput!
    $condition: ModelHiddenPersonAccountAgentSignConditionInput
  ) {
    deleteHiddenPersonAccountAgentSign(input: $input, condition: $condition) {
      id
      personOID
      createdAt
      updatedAt
      owner
    }
  }
`;
