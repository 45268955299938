<template>
  <div>
    <transition-group
      name="group-slide-left"
      tag="div"
      appear
    >
      <div 
        v-for="(property, index) in linkedAndFavProperties" :key="index" 
        :style="`--i: ${index}`"
      >
        <v-hover 
          v-slot="{ hover }"
        >
          <v-card
            @click="makeSelectedProperty(property)" 
            flat 
            class="rounded-sm mb-3"
            :class="returnCardColor(property)"
            :outlined="property !== selectedProperty"
            :elevation="hover ? 16 : 0"
          > 
            <v-card-title class="py-1 pr-1 d-flex align-start">
              <v-row>
                <v-col cols="9">
                  <div v-if="selectedPersonAccount.personType === 'Applicant Tenant'" class="mt-1">
                    <p class="my-0 text-body-1 font-weight-medium">{{ property.TenancyProperty.AddressNumber }} {{ property.TenancyProperty.Address1 }}</p>
                    <p v-if="property.TenancyProperty.Address2" class="my-0 text-caption text-md-body">{{ property.TenancyProperty.Address2 }}</p>
                    <p v-else class="my-0 text-caption text-md-body">{{ property.TenancyProperty.Postcode}}</p>
                  </div>
                  <div v-if="selectedPersonAccount.personType === 'Applicant Buyer'" class="mt-1">
                    <p class="my-0 text-body-1 text-md-h6">{{ property.AddressNumber }} {{ property.Address1}}</p>
                    <p v-if="property.Address2" class="my-0 text-caption text-md-body">{{ property.Address2 }}</p>
                    <p v-else class="my-0 text-caption text-md-body">{{ property.Postcode }}</p>
                  </div>
                </v-col>
                <v-col class="text-right" cols="3">
                  <v-menu
                    transition="slide-x-reverse-transition"
                    offset-x
                    left
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mx-0 mt-n1"
                        large
                        icon
                        @click.stop
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon size="21" :color="property === selectedProperty ? 'white' : 'grey'" v-if="isLinkedProperty(property)">mdi-link-variant</v-icon>
                        <v-icon color="orange" v-else>mdi-star</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item v-if="isLinkedProperty(property)">
                        <v-list-item-title class="red--text">Linked property</v-list-item-title>
                      </v-list-item>
                      <v-list-item v-else @click="removeFromFavourites(property)">
                        <v-list-item-title>Remove from favourites</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text class="">
              <div v-if="appointmentBooked(property)" class=" d-flex align-center justify-center">
                <v-icon 
                  size="20"
                  class="mr-1" 
                  :color="property === selectedProperty ? 'white' : 'orange'" 
                >mdi-clock-time-four-outline</v-icon>
                <span 
                  class="text-body-1 font-weight-light"
                  :class="property === selectedProperty ? 'white--text' : 'secondary--text'"
                >
                  Viewing
                </span>
              </div>
            </v-card-text>
          </v-card>
        </v-hover>
      </div>
    </transition-group>

    <div v-if="linkedAndFavProperties.length < 1">
      <v-card flat class="grey lighten-2 rounded-sm">
        <v-card-text>
          <div class="text-center">
            <v-icon size="45" color="grey lighten-1">mdi-home</v-icon>
            <p class="mt-2 text-body-1 font-weight-light grey--text">Liked properties will be listed here</p>
          </div>
        </v-card-text>
      </v-card>
    </div>
    
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { API, graphqlOperation  } from 'aws-amplify'
import { deleteFavouriteProperty } from '../../graphql/mutations'
// import AppointmentChecker from '@/components/property/AppointmentChecker.vue';

export default {
  name: 'favPropertyList',
  props: {
    linkedAndFavProperties: {
      type: Array
    }
  },
  components: {
    // AppointmentChecker
  },
  mounted() {
    this.$nextTick(() => {
      if(!this.linkedProperties) {
        if(this.linkedAndFavProperties.length) {
          this.makeSelectedProperty(this.linkedAndFavProperties[0])
        }
      }
    })
  },
  data() {
    return {
      favFullDetails: '',
    }
  },
  computed: {
    ...mapState({
      selectedPersonAccount: state => state.selectedPersonAccount,
      selectedProperty: state => state.selectedProperty,
      favourites: state => state.favouriteProperties,
      linkedProperties : state => state.linkedProperties,
      selectedAccountAppointments: state => state.selectedAccountAppointments
    }),
    appointmentsInFuture() {
      const nowDateTime = Date.now()
      // const futureAppointments = Date.parse("2018-11-20T12:30:00")
      const futureAppointments = this.selectedAccountAppointments.filter(appointment => {
        let parsedEndDate = Date.parse(appointment.End)
        if(parsedEndDate > nowDateTime) return appointment
      })
      return futureAppointments
    },
    futureAppointmentsPropertyIDs() {
      if(this.selectedAccountAppointments.length) {
        const propertyIDs = []
        this.appointmentsInFuture.forEach(item => {
          const idArray = item.PropertyIDs
          idArray.forEach(id => propertyIDs.push(id))
        })
        return propertyIDs
      } 
      return []
    },
  },
  methods: {
    appointmentBooked(property) {
      if(this.selectedPersonAccount.personType === 'Applicant Tenant') {
         const propertyHasAppointment  = this.futureAppointmentsPropertyIDs.includes(property.TenancyProperty.OID) // appointment must be in the future
         if(propertyHasAppointment) {
           return true
         }  
         return false
       } 
       else if(this.selectedPersonAccount.personType === 'Applicant Buyer') {
         const propertyHasAppointment  = this.futureAppointmentsPropertyIDs.includes(property.OID) // appointment must be in the future
         if(propertyHasAppointment) {
           return true
         }  
         return false
       } 
       return false
    },
    isLinkedProperty(property) {
      if(this.linkedProperties) {
        return this.linkedProperties.some(item => item.OID === property.OID)
      } return false
    },
    removeFromFavourites(property) {
      const fullFavObject = this.favourites.find( fav => fav.propertyOID === property.OID)
      const propertyAmplifyID = { id: fullFavObject.id }

      API.graphql(graphqlOperation(deleteFavouriteProperty, {input: propertyAmplifyID}))
        .then( () => {
          this.$emit('updateSavedFavourites')
        })
    },
    makeSelectedProperty(property) {
      this.$store.commit('SET_SELECTED_PROPERTY', property)
      this.$emit('propertySelected')
      this.$vuetify.goTo(0)
    },
    returnCardColor(property) {
      if(property === this.selectedProperty) {
        return 'tertiaryDark white--text'
      }
      else {
        return 'grey--text'
      }
    }
  },
}
</script>

<style scoped>
.slide-left-enter-active {
  transition: all calc(var(--i) * .3s);
}
.slide-left-enter {
  opacity: 0;
  transform: translateX(25px) translateY(25px);
}
</style>