<template>
  <div>
    <v-card flat>
      <v-card-title class="primary">
        <span class="text-h5 white--text">{{ propertyMakeOffer.AddressNumber }} {{ propertyMakeOffer.Address1 }}</span>
        <v-spacer></v-spacer>
        <v-btn @click="cancelOffer" color="white" text>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pt-5">
        <div v-if="offerSubmitionStatus">
          <p v-if="offerSubmitionStatus === '200'" class="green--text text-h6">Offer submitted successfully.</p>
          <p v-if="offerSubmitionStatus !== '200'" class="red--text text-h6">Error submitting offer. Try again.</p>
        </div>
        <v-form
          v-if="!offerSubmitionStatus"
          ref="form"
          v-model="valid"
        >
          <v-row>
            <v-col cols="12">
              <v-text-field
                dense
                v-model="property"
                label="Property"
                readonly
                required
                outlined
              ></v-text-field>
              <v-text-field
                dense
                v-model="offerPrice"
                :rules="[v => !!v || 'Valid price is required']"
                label="My offer"
                :placeholder="advertisedPrice" 
                outlined
                prefix="£"
              ></v-text-field>
              <v-radio-group
                v-model="subjectToMortgate"
                row
                class="mt-0"
              >
                <v-radio
                  label="Offer subject to mortgage"
                  value="true"
                ></v-radio>
                <v-radio
                  label="Cash payment"
                  value="false"
                ></v-radio>
              </v-radio-group>
              <v-textarea
                v-if="subjectToMortgateBoolean"
                v-model="mortageDetails"
                auto-grow
                outlined
                label="Mortgage details"
                placeholder="What goes here...?"
                rows="3"
                row-height="31"
              ></v-textarea>
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class=""
                    dense
                    :value="formattedDate"
                    label="Move in date"
                    outlined
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="moveInDate"
                  @input="dateMenu = false"
                ></v-date-picker>
              </v-menu> 
              <v-textarea
                v-model="requestsConditions"
                auto-grow
                outlined
                label="Requests & Conditions"
                rows="3"
                row-height="31"
              ></v-textarea>
              <!-- <p class="mt-n4 mb-5"><span>Advertised at:</span><span class="font-weight-medium ml-2">{{ propertyMakeOffer.Price | filterPriceGlobal }}</span></p> -->
              <!-- <v-file-input
                class="mt-8"
                dense
                label="Valid ID document"
                outlined
                multiple
                truncate-length="20"
                append-icon="mdi-paperclip"
                prepend-icon=""
              ></v-file-input> -->
              <!-- <v-file-input
                dense
                label="Proof of address"
                outlined
                multiple
                truncate-length="20"
                append-icon="mdi-paperclip"
                prepend-icon=""
              ></v-file-input> -->
              <!-- <v-file-input
                dense
                label="Proof of funds for deposit"
                outlined
                multiple
                truncate-length="20"
                append-icon="mdi-paperclip"
                prepend-icon=""
              ></v-file-input> -->
            </v-col>
          </v-row>
          <v-btn
            @click="confirmOfferDialog = true"
            class="mt-auto mb-6"
            x-large
            block
            depressed
            :disabled="!offerPrice"
            color="primary"
          >
            Send offer
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="confirmOfferDialog"
      max-width="500px"
      transition="dialog-transition"
      scrollable
      persistent
    >
      <v-card>
        <v-card-title class="primary darken-4">
          <span class="white--text text-h6">Confirm and submit</span>
          <v-spacer></v-spacer>
          <v-btn @click="confirmOfferDialog = false" color="white" outlined>Edit</v-btn>
        </v-card-title>
        <v-card-text class="">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    <!-- empty -->
                  </th>
                  <th class="text-left">
                    <!-- empty -->
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Property</td>
                  <td><span class="font-weight-bold">{{ property }}</span></td>
                </tr>
                <tr>
                  <td>Offer</td>
                  <td><span class="font-weight-bold">£{{ offerPrice }}</span></td>
                </tr>
                <tr>
                  <td>Payment</td>
                  <td v-if="!subjectToMortgateBoolean"><span class="font-weight-bold">Cash</span></td>
                  <td v-else><span class="font-weight-bold">Subject to mortgage</span></td>
                </tr>
                <tr v-if="subjectToMortgateBoolean">
                  <td>Mortgage details</td>
                  <td><span class="font-weight-bold">{{ mortageDetails }}</span></td>
                </tr>
                <!-- <tr>
                  <td>ID Document</td>
                  <td><span class="font-weight-bold">{{ idDocument }}</span></td>
                </tr> -->
                <!-- <tr>
                  <td>Proof of address</td>
                  <td><span class="font-weight-bold">{{ proofOfAddress }}</span></td>
                </tr> -->
                <!-- <tr>
                  <td>Proof of funds</td>
                  <td><span class="font-weight-bold">{{ proofOfFunds }}</span></td>
                </tr> -->
                <tr>
                  <td>Move in date</td>
                  <td><span class="font-weight-bold">{{ moveInDate }}</span></td>
                </tr>
                <tr>
                  <td>Requests & Conditions</td>
                  <td><span class="font-weight-bold">{{ requestsConditions }}</span></td>
                </tr>

              </tbody>
            </template>
          </v-simple-table>
          <v-btn
            @click="submitOffer"
            :loading="loading"
            class="mt-5"
            depressed 
            color="primary"
            x-large 
            block
          >
            <span class="white--text">Submit offer</span>
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog> 
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { API } from 'aws-amplify'

export default {
  name: 'salesOfferForm',
  created() {
    this.property = `${this.propertyMakeOffer.AddressNumber} ${this.propertyMakeOffer.Address1}, ${this.propertyMakeOffer.Address2}, ${this.propertyMakeOffer.Postcode}`
  },
  data: () => ({
    confirmOfferDialog: false,
    valid: true, // Form rules
    loading: false,
    property: '',
    offerPrice: null,
    offerPriceRules: [
      v => !!v || "Valid price is required",
    ],
    requestsConditions: '',
    subjectToMortgate: 'true',
    mortageDetails: '',
    moveInDate: null,
    dateMenu: false,
    offerSubmitionStatus: ''
    // idDocument: '',
    // proofOfAddress: '',
    // proofOfFunds: '',
  }),
  methods: {
    validate () {
      this.$refs.form.validate()
      if(this.valid) this.confirmOfferDialog = true
    },
    reset () {
      this.$refs.form.reset()
    },
    getContactText(item) {
      return `${item.position} - ${item.name}`
    },
    submitOffer() {
      this.loading = true

      const offerNotes = `
        Move in date: ${this.moveInDate}, /n 
        Mortgage details: ${this.mortageDetails}, /n
      `

      const submissionModel = {
        Amount: this.offerPriceToNumber,
        Notes: offerNotes,
        SpecialTerms: this.requestsConditions,
        FixtureFittings: '',
        DepositAmount: 0,
        DepositType: 'n/a',
        SubjectToMortgate: this.subjectToMortgateBoolean,
      }

      const postParams = {
        body: submissionModel
      }

      API.post('agentSignMainRest', `/post?query=/${this.clientName}/${this.personOID}/sales/${this.propertyMakeOffer.OID}/offers`, postParams)
        .then(res => {
          if(res.status === '200') {
            console.log('Offer submition response: ', res)
            this.loading = false
            this.offerSubmitionStatus = res.status
            this.confirmOfferDialog = false
          } else {
            // inform user of error
            this.loading = false
            this.offerSubmitionStatus = res.status
            this.confirmOfferDialog = false
          }
        })
    },
    cancelOffer() {
      this.property = ''
      this.offerPrice = ''
      this.requestsConditions = ''
      this.subjectToMortgate = 'true'
      this.mortageDetails = ''
      this.offerSubmitionStatus = ''
      this.moveInDate = '',
      this.$emit('cancelOffer') // emits to ProspectOffer.vue
    }
  },
  computed: {
    ...mapState({
      selectedPersonAccount: state => state.selectedPersonAccount,
      clientName: state => state.selectedPersonAccount.companyShortname,
      personOID: state => state.selectedPersonAccount.personOID,
      propertyMakeOffer: state => state.propertyMakeOffer,
    }),
    formattedDate() {
      if (!this.moveInDate) return null

      const [year, month, day] = this.moveInDate.split('-')
      return `${day}/${month}/${year}`
    },
    offerPriceToNumber() {
      if(this.offerPrice) {
        let noCommas = this.offerPrice.replaceAll(",", "")
        return parseInt(noCommas)
      } return null
    },
    advertisedPrice() {
      if (!this.propertyMakeOffer) return ''
      
      const formattedString = this.propertyMakeOffer.Price.toLocaleString('en-UK', { style: 'currency', currency: 'GBP' })
      const formattedPrice = formattedString.slice(0, -3)
      return `Advertised at ${formattedPrice}`
    },
    subjectToMortgateBoolean() {
      if(this.subjectToMortgate === 'true') return true
      return false
    }
  },
  watch: {
    offerPrice(newValue) {
      const result = newValue.replace(/\D/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.$nextTick(() => this.offerPrice = result);
    }
  }
}
</script>

<style>

</style>