<template>
  <div>
    <v-row>
      <v-col v-if="propertyPhotos.length" cols="12">
        <v-row no-gutters class="image-row">
          <v-col class="d-flex align-center justify-center" cols="12" :lg="searchResultProperty ? '' : '8' ">
            <v-img 
              :height="searchResultProperty ? 200 : 225" 
              :src="propertyPhotos[0].WebAddress"
              lazy-src="../../assets/livingRoom.jpg"
              class="rounded-tl-sm"
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
              <v-btn 
                v-if="propertyPhotos.length > 1" 
                @click="openCarousel" 
                color="secondary" 
                rounded
                dark 
                depressed
                absolute 
                bottom 
                right 
                small
                class="mr-n1 mb-n1"
              >
                <span class="mr-1">+{{ propertyPhotos.length }}</span>
                <v-icon small>mdi-camera-outline </v-icon>
              </v-btn>
            </v-img>
          </v-col>
          <v-col v-if="!searchResultProperty" class="pl-1 d-none d-lg-flex flex-column" cols="12" lg="4">
            <v-img
              v-if="propertyPhotos.length > 1"
              :height="searchResultProperty ? 88 : '' " 
              class="mb-1" 
              :src="propertyPhotos[1].WebAddress"
              lazy-src="../../assets/livingRoom.jpg"
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <v-card v-else flat class="d-flex align-center justify-center grey lighten-2 rounded-0 ma-1" height="100%" >
              <v-icon color="white">mdi-camera-off</v-icon>
            </v-card>

            <v-img
               v-if="propertyPhotos.length > 2" 
              :height="searchResultProperty ? 88 : '' " 
               :src="propertyPhotos[2].WebAddress"
               lazy-src="../../assets/livingRoom.jpg"
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <v-card v-else flat class="d-flex align-center justify-center grey lighten-2 rounded-0 ma-1" height="100%" >
              <v-icon color="white">mdi-camera-off</v-icon>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'propertyImageColumn',
  props: {
     propertyPhotos: {
       type: Array,
        default: function () {
          return []
        }
     },
     searchResultProperty: {
       type: Boolean,
       default: false,
     }
  },
  methods: {
    openCarousel() {
      this.$emit('openCarousel')
    }
  }
}
</script>

<style>

</style>