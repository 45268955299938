<template>
  <div>
    <v-toolbar 
      height="60"
      flat
      class="secondary py-3 px-2 px-md-9"
    >
      <!-- Mobile dark bar with textfield and filter btn -->
      <v-row align="center" class="mobile-ipad-toolbar d-md-none">
        <v-col class="pl-0 d-flex" cols="8" sm="4">
           <v-text-field
              @keyup.enter="searchProperties"
              @click:append="searchProperties"
              v-model="address"
              :value="address"
              outlined
              dense
              background-color="white"
              placeholder="E.g. Cardiff"
              append-icon="mdi-home-search-outline"
            ></v-text-field>
        </v-col>
        <v-col class="d-flex justify-end pr-0 mt-n6" cols="4" sm="4" offset-sm="4">
          <v-btn 
            @click="showMobileFilters = !showMobileFilters" 
            color="white" 
            outlined
            class="rounded-sm"
          >
            filters 
            <v-icon right>mdi-tune</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <!-- Medium screen AND up dark bar with selects and btns -->
      <!-- ==================================================================================== -->
      <v-row class="desktop-toolbar d-none d-md-flex mt-n9">
        <v-col class="pl-0" cols="12" md="2" lg="3">
          <v-row class="mt-3" align="center">
            <v-col cols="12" class="">
              <v-text-field
                @keyup.enter="searchProperties"
                @click:append="searchProperties"
                @input="isTyping = true"
                v-model="address"
                :value="address"
                outlined solo
                dense
                background-color="white"
                placeholder="E.g. Cardiff"
                append-icon="mdi-home-search-outline"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="pr-0" cols="12" md="10" lg="9">
          <v-row class="mt-3" align="center" justify="end">
            <v-col class="" cols="12" md="2" lg="2">
              <v-select
                v-model.number="minBeds"
                :items="minBedNumber"
                label="Min beds"
                dense 
                outlined
                @change="refreshSearch"
                background-color="secondary" dark
              ></v-select>
            </v-col>
            <v-col class="" cols="12" md="2" lg="2">
              <v-select
                :disabled="selectedPersonAccount.personType === 'Applicant Buyer'"
                v-model.number="maxBeds"
                :items="maxBedNumber"
                label="Max beds"
                dense
                outlined
                @change="refreshSearch"
                background-color="secondary" dark
              ></v-select>
            </v-col>
            <v-col class="" cols="12" md="2" lg="2">
              <v-currency-field 
                @keydown.enter="searchProperties"
                v-model.number="minPrice"
                item-text="displayValue"
                item-value="value"
                label="Min price"
                dense
                outlined
                background-color="secondary" dark
                prefix="£"
              />
            </v-col>
            <v-col class="" cols="12" md="2" lg="2">
              <v-currency-field 
                @keydown.enter="searchProperties"
                v-model.number="maxPrice"
                item-text="displayValue"
                item-value="value"
                label="Max price"
                dense
                outlined
                background-color="secondary" 
                dark
                prefix="£"
              />
            </v-col>
            <v-col class="" cols="12" md="4" lg="3">
              <v-select
                multiple
                v-model="propertyType"
                :items="$store.state.applicantSearchPropertyTypes"
                item-text="DisplayName"
                item-value="EnumValue"
                label="Type"
                dense 
                outlined
                @change="refreshSearch"
                background-color="secondary" 
                dark
              >
                <template v-slot:prepend-item>
                  <v-list-item
                    ripple
                    @click="togglePropertyType"
                  >
                    <v-list-item-action>
                      <v-icon :color="propertyType.length > 0 ? 'green' : ''">
                        {{ propertyTypeSelectIcon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span v-if="allPropertyTypesSelected">Unselect All</span>
                        <span v-else>Select All</span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
                <template v-slot:selection="{ item, index }">
                  <span v-if="index === 0" class="white--text">{{item.DisplayName}}</span>
                  <span
                    v-if="index === 1"
                    class="grey--text caption ml-2"
                  >
                    (+{{ propertyType.length - 1 }})
                  </span>
                </template>
              </v-select>
            </v-col>
            <v-col class="d-none d-lg-flex align-center justify-center mt-n6" cols="12" md="1" lg="1">
              <v-btn 
                @click="searchProperties" 
                color="primary" 
                depressed
                width="30"
                class="rounded-sm"
              >
                <span class="d-none d-lg-inline-block secondary--text custom-transform-class text-none">Search</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row> 
    </v-toolbar>

    <!-- Mobile / Pad expansion menu -->
    <v-expand-transition>
      <v-card class="" v-if="showMobileFilters">
        <v-card-text class="secondary--text py-3">
          <v-container >
            <!-- Dialog filters -->
            <v-row align="center">
              <v-col class="my-0 py-0" cols="12" sm="6">
                <v-row>
                  <v-col cols="12">
                    <span class="headline font-weight-regular">Beds</span>
                  </v-col>
                  <v-col cols="6" class="mt-n3">
                    <v-select
                      color="primary"
                      v-model.number="minBeds"
                      :items="minBedNumber"
                      label="Min"
                      dense outlined
                    ></v-select>
                  </v-col>
                  <v-col cols="6" class="mt-n3">
                   <v-select
                    color="primary"
                    :disabled="selectedPersonAccount.personType === 'Applicant Buyer'"
                    v-model.number="maxBeds"
                    :items="maxBedNumber"
                    label="Max"
                    dense outlined
                   ></v-select>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="my-0 py-0" cols="12" sm="6">
                <v-row>
                  <v-col cols="12">
                    <span class="headline font-weight-regular">Price</span>
                  </v-col>
                  <v-col class="mt-n3" cols="6">
                    <v-currency-field 
                      v-model.number="minPrice"
                      item-text="displayValue"
                      item-value="value"
                      label="Min"
                      dense
                      outlined
                      prefix="£"
                    />
                  </v-col>
                  <v-col class="mt-n3" cols="6">
                    <v-currency-field 
                      v-model.number="maxPrice"
                      item-text="displayValue"
                      item-value="value"
                      label="Max"
                      dense
                      outlined
                      prefix="£"
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="my-0 py-0" cols="12" sm="6">
                <v-row>
                  <v-col cols="12">
                    <span class="headline font-weight-regular">Property type</span>
                  </v-col>
                  <v-col class="mt-n3" cols="12" offset="">
                    <v-select
                      multiple
                      v-model="propertyType"
                      :items="$store.state.applicantSearchPropertyTypes"
                      item-text="DisplayName"
                      item-value="EnumValue"
                      label="Type"
                      dense 
                      outlined
                    >
                      <template v-slot:prepend-item>
                        <v-list-item
                          ripple
                          @click="togglePropertyType"
                        >
                          <v-list-item-action>
                            <v-icon :color="propertyType.length > 0 ? 'green' : ''">
                              {{ propertyTypeSelectIcon }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              <span v-if="allPropertyTypesSelected">Unselect All</span>
                              <span v-else>Select All</span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                      <template v-slot:selection="{ item, index }">
                        <span v-if="index === 0" class="">{{item.DisplayName}}</span>
                        <span
                          v-if="index === 1"
                          class="grey--text caption ml-2"
                        >
                          (+{{ propertyType.length - 1 }} others)
                        </span>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <div class="d-flex mt-3">
              <v-btn 
                @click="showMobileFilters = false" 
                depressed 
                outlined 
                color="primary"
                class="rounded-sm"
              >
                <span>Close</span>
                <v-icon right>mdi-chevron-up</v-icon>
              </v-btn>
              <v-btn 
                @click="startMobileSearch" 
                depressed 
                color="primary"
                class="ml-auto mr-3 rounded-sm" 
                >
                <span class="white--text custom-transform-class text-none">Search</span>
              </v-btn>
            </div>
          </v-container>
        </v-card-text>
      </v-card>
    </v-expand-transition>

    <v-overlay v-if="advertisedTenancies || advertisedSales" :value="overlayLoader" opacity="0.8">
      <div class="text-center">
        <v-progress-circular
          indeterminate
          size="48"
        ></v-progress-circular>
        <p class="mt-3 white--text text-h2 text-center">Searching for relevant properties...</p>
      </div>
    </v-overlay>

    <v-snackbar
      v-model="snackbar"
      color="agentPrimary"
      shaped
      class="mb-14 mb-md-3"
      :timeout="timeout"
    >
      <div v-if="selectedPersonAccount.personType === 'Applicant Tenant'" class="white--text text-body-1">
        <span v-if="advertisedTenancies.Count >= 1" >We`ve found you 
          <span class="font-weight-bold">{{ advertisedTenancies.Count }}</span> relevant
          <span v-if="advertisedTenancies.Count > 1">properties!</span>          
          <span v-else>property!</span>
        </span>
        <span v-if="advertisedTenancies.Count === 0" >No properties matching your search, sorry.</span>
      </div>
      <div v-if="selectedPersonAccount.personType === 'Applicant Buyer'" class="white--text text-body-1">
        <span v-if="advertisedSales.Count >= 1" >We`ve found you 
          <span class="font-weight-bold">{{ advertisedSales.Count }}</span> relevant
          <span v-if="advertisedSales.Count > 1">properties!</span>          
          <span v-else>property!</span>
        </span>
        <span v-if="advertisedSales.Count === 0" >No properties matching your search, sorry.</span>
      </div>
      
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    
    <v-dialog
      v-model="searchInfoDialog"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-text class="pt-8 pb-0 text-body-1">
          <span>To search for </span>
          <span class="font-weight-bold" v-if="selectedPersonAccount.personType === 'Applicant Tenant'">Sales,</span>
          <span class="font-weight-bold" v-if="selectedPersonAccount.personType === 'Applicant Buyer'">Lettings,</span>
          <span> switch to </span>
          <span class="" v-if="selectedPersonAccount.personType === 'Applicant Tenant'">an "Applicant Buyer" account.</span>
          <span class="" v-if="selectedPersonAccount.personType === 'Applicant Buyer'">an "Applicant Tenant" account.</span>
          <p class="mt-3">You can select accounts on the homepage or in the top-left menu.</p>
          <p class="mt-3">Please contact support if you need to create a new account.</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="searchInfoDialog = false" color="tertiary" depressed dark>Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { API } from 'aws-amplify'
import debounce from 'lodash/debounce'

export default {
  name: 'searchToolbar',
  data() {
    return {
      showMobileFilters: false,
      overlayLoader: false,
      snackbar: false,
      timeout: 3500,
      searchInfoDialog: false,
      // Search Filter Values
      address: '',
      isTyping: false, 
      minBeds: '',
      maxBeds: '',
      minPrice: '',
      maxPrice: '',
      propertyType: '',
      minBedNumber: ['No min',1,2,3,4,5,6,7,8],
      maxBedNumber: ['No max',1,2,3,4,5,6,7,8],
      salesOnlyDevelopment: false,
      salesOnlyInvestment: false
    }
  },
  created() {
    if(this.searchPreferences && this.address === '') {
      this.onlySetSearchParametres() // saves an api call and only renders UI values for user
    }
  },
  computed: {
    ...mapState({
      linkedProperties: state => state.linkedProperties,
      searchPreferences: state => state.searchPreferences,
      advertisedTenancies: state => state.advertisedTenancies,
      advertisedSales: state => state.advertisedSales,
      selectedPersonAccount: state => state.selectedPersonAccount,
      clientName: state => state.selectedPersonAccount.companyShortname,
      personOID: state => state.selectedPersonAccount.personOID,
      applicantSearchPropertyTypes: state => state.applicantSearchPropertyTypes,
      selectedProperty: state => state.selectedProperty,
      favouriteProperties: state => state.favouriteProperties
    }),
    allPropertyTypesSelected() {
      return this.propertyType.length === this.applicantSearchPropertyTypes.length
    },
    somePropertyTypesSelected() {
      return this.propertyType.length > 0 && !this.allPropertyTypesSelected
    },
    propertyTypeSelectIcon() {
      if(this.allPropertyTypesSelected) return 'mdi-close-box'
      if (this.somePropertyTypesSelected) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
  methods: {
    togglePropertyType() {
      this.$nextTick(() => {
        if (this.allPropertyTypesSelected) {
          this.propertyType = []
        } else {
          this.propertyType = this.applicantSearchPropertyTypes.slice()
        }
      })
    },
    searchProperties() {
      if(this.selectedPersonAccount.personType === 'Applicant Tenant') { this.searchTenancies() }
      if(this.selectedPersonAccount.personType === 'Applicant Buyer') { this.searchSales() }
    }, 
    startMobileSearch() {
      this.showMobileFilters = false
      this.searchProperties()
    },
    getLettingsSearchPreferences() { //being called from Property.vue via $refs once LinkedProperties are set in $store
      this.getTenancyPropertyTypeList() 
      API.get('agentSignMainRest', `/get?query=/${this.clientName}/preferences/lettingsprospect/${this.personOID}`)
        .then(res => {
          this.$store.commit('SET_APPLICANT_SEARCH_PREFERENCES', res.data)
          this.setTenancySearchParameters()   
        })
    },
    getSalesSearchPreferences() { //being called from Property.vue via $refs once LinkedProperties are set in $store
      this.getSalesPropertyTypeList()
      API.get('agentSignMainRest', `/get?query=/${this.clientName}/preferences/salesprospect/${this.personOID}`)
        .then(res => {
          this.$store.commit('SET_APPLICANT_SEARCH_PREFERENCES', res.data) // if search filters get more complicated, we`ll need a separate mutation in $store for sales
          this.setSalesSearchParameters()   
        })
    },
    onlySetSearchParametres() {
      // Called when search parametres are saved in $store but not rendered
      // no api call made - just setting the values in component
      this.setPropertyAddress()
      this.propertyType = this.$store.state.applicantSearchPropertyTypes

      if(this.selectedPersonAccount.personType === 'Applicant Tenant') {
        this.minBeds = this.searchPreferences.MinBeds
        this.maxBeds = this.searchPreferences.MaxBeds
        this.minPrice = this.searchPreferences.MinimumRent
        this.maxPrice = this.searchPreferences.MaximumRent
      }
      if(this.selectedPersonAccount.personType === 'Applicant Buyer') {
        this.minBeds = this.searchPreferences.MinimumBedrooms
        this.maxBeds = 1 // HARD CODED UNTIL VALUES ADDED TO API ENDPOINT
        this.minPrice = '' // HARD CODED UNTIL VALUES ADDED TO API ENDPOINT
        this.maxPrice = this.searchPreferences.MaximumPrice
      }
    },
    setTenancySearchParameters() {
      this.setPropertyAddress()
      this.minBeds = this.searchPreferences.MinBeds
      this.maxBeds = this.searchPreferences.MaxBeds
      this.minPrice = this.searchPreferences.MinimumRent
      this.maxPrice = this.searchPreferences.MaximumRent
      this.searchTenancies()
    },
    setSalesSearchParameters() {
      this.setPropertyAddress() // might need its own sales method if search filters get more complicated
      this.minBeds = this.searchPreferences.MinimumBedrooms
      this.maxBeds = '' // HARD CODED UNTIL VALUES ADDED TO API ENDPOINT
      this.minPrice = this.searchPreferences.MinimumPrice
      this.maxPrice = this.searchPreferences.MaximumPrice
      this.searchSales()
    },
    setPropertyAddress() {
      if(this.linkedProperties && this.linkedProperties.length) {
        if(this.selectedPersonAccount.personType === 'Applicant Tenant') {
          if(this.linkedProperties[0].TenancyProperty.Address4) {
            this.address = this.linkedProperties[0].TenancyProperty.Address4
          } else if(this.linkedProperties[0].TenancyProperty.Address3) {
              this.address = this.linkedProperties[0].TenancyProperty.Address3
            } else {
                this.address = this.linkedProperties[0].TenancyProperty.Postcode
              }
        } else { // not applicant tenant
            if(this.linkedProperties[0].Address4) {
              this.address = this.linkedProperties[0].Address4
            } else if(this.linkedProperties[0].Address3) {
                this.address = this.linkedProperties[0].Address3
              } else {
                  this.address = this.linkedProperties[0].Postcode
                }
          }
      } 
      else if(this.selectedProperty) { // no linked property
        if(this.selectedPersonAccount.personType === 'Applicant Tenant') {
          if(this.selectedProperty.TenancyProperty.Address4) {
            this.address = this.selectedProperty.TenancyProperty.Address4
          } else if(this.selectedProperty.TenancyProperty.Address3) {
              this.address = this.selectedProperty.TenancyProperty.Address3
            } else {
                this.address = this.selectedProperty.TenancyProperty.Postcode
              }
        } else { // not applicant tenant
            if(this.selectedProperty.Address4) {
              this.address = this.selectedProperty.Address4
            } else if(this.selectedProperty.Address3) {
                this.address = this.selectedProperty.Address3
              } else {
                  this.address = this.selectedProperty.Postcode
                }
          }
      }
      else {
        this.address = ''
      }
    },
    getTenancyPropertyTypeList() {
      API.get('agentSignMainRest', `/searchSales?query=/${this.clientName}/${this.personOID}/tenancies/propertyTypes`)
        .then(res => {
          this.$store.commit('SET_APPLICANT_SEARCH_PROPERTY_TYPES', res.data)
          this.propertyType = [...res.data]
        })
    },
    getSalesPropertyTypeList() {
      API.get('agentSignMainRest', `/searchSales?query=/${this.clientName}/${this.personOID}/sales/propertyTypes`)
        .then(res => {
          this.$store.commit('SET_APPLICANT_SEARCH_PROPERTY_TYPES', res.data)
          this.propertyType = [...res.data]
        })
    },
    searchTenancies() {
      this.overlayLoader = true
      API.get('agentSignMainRest', `/searchTenancies?query=/${this.clientName}/${this.personOID}/tenancies/advertised/0/1000&rentMinimum=${this.minPrice}&rentMaximum=${this.maxPrice}&address=${this.address}&minimumTenants=${this.minBeds}&maximumTenants=${this.maxBeds}&propertyType=${this.propertyType}`)
        .then(res => {
          this.$store.commit('SET_TENANCY_ADVERTISED', res.data)
          this.overlayLoader = false
          this.snackbar = true
        })
    },
    searchSales() {
      this.overlayLoader = true
      API.get('agentSignMainRest', `/searchSales?query=/${this.clientName}/${this.personOID}/sales/advertised/0/1000&minimumPrice=${this.minPrice}&maximumPrice=${this.maxPrice}&address=${this.address}&minimumBeds=${this.minBeds}&maximumBedrooms=${this.maxBeds}&propertyType=${this.propertyType}&onlyDevelopement=${this.salesOnlyDevelopment}&onlyInvestements=${this.salesOnlyInvestment}`)
        .then(res => {
          this.$store.commit('SET_SALES_ADVERTISED', res.data)
          this.overlayLoader = false
          this.snackbar = true
        })
    },
    refreshSearch() {
      if(this.selectedPersonAccount.personType === 'Applicant Tenant') { this.searchTenancies() }
      if(this.selectedPersonAccount.personType === 'Applicant Buyer') { this.searchSales() }
    }
  },
  watch: {
    address: debounce(function() {
      this.isTyping = false;
    }, 1000),
  }
}
</script>

<style>
.theme--dark.v-icon {
  color: '#59ddb7' !important;
}
</style>