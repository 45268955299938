/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPersonAccount = /* GraphQL */ `
  query GetPersonAccount($id: ID!) {
    getPersonAccount(id: $id) {
      id
      companyName
      accountCreated
      displayInformation
      personName
      personOID
      companyShortname
      personType
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listPersonAccounts = /* GraphQL */ `
  query ListPersonAccounts(
    $filter: ModelPersonAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPersonAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyName
        accountCreated
        displayInformation
        personName
        personOID
        companyShortname
        personType
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getPersonAccountSyncDateTime = /* GraphQL */ `
  query GetPersonAccountSyncDateTime($id: ID!) {
    getPersonAccountSyncDateTime(id: $id) {
      id
      lastUpdate
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listPersonAccountSyncDateTimes = /* GraphQL */ `
  query ListPersonAccountSyncDateTimes(
    $filter: ModelPersonAccountSyncDateTimeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPersonAccountSyncDateTimes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lastUpdate
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getFavouriteProperty = /* GraphQL */ `
  query GetFavouriteProperty($id: ID!) {
    getFavouriteProperty(id: $id) {
      id
      propertyOID
      personAccountID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listFavouritePropertys = /* GraphQL */ `
  query ListFavouritePropertys(
    $filter: ModelFavouritePropertyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFavouritePropertys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        propertyOID
        personAccountID
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getHiddenPersonAccountAgentSign = /* GraphQL */ `
  query GetHiddenPersonAccountAgentSign($id: ID!) {
    getHiddenPersonAccountAgentSign(id: $id) {
      id
      personOID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listHiddenPersonAccountAgentSigns = /* GraphQL */ `
  query ListHiddenPersonAccountAgentSigns(
    $filter: ModelHiddenPersonAccountAgentSignFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHiddenPersonAccountAgentSigns(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        personOID
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
